import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // 确保路径正确
import React from 'react';

const PrivateRoute = ({children}) => {
  const { user } = useAuth();

  return user ? children : <Navigate to="/login"  replace/>;
};

export default PrivateRoute;


