import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import Validation from './RegisterValidation';
import axios from 'axios';
import Card from 'react-bootstrap/Card';

const RegisterForm = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    passwordRepeat: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInput = (event) => {
    setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = Validation(values);
    console.log("Form values before validation:", values);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const res = await axios.post(`${window.baseURL}/register`, {
          username: values.name,
          email: values.email,
          password: values.password
        });
        console.log(res);
        navigate('/'); // Navigate to home after successful registration
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          setErrors({ server: err.response.data.message });
        } else {
          setErrors({ server: "Registration failed. Please try again." });
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Validation errors:", validationErrors);
      setErrors(validationErrors);
    }
  };

  return (
    <Container className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
      <h1 className='text-center fs-1 fw-bold'>EV2L TRADE</h1>
      <Card className='shadow-sm p-4' style={{ width: '400px' }}>
        <Card.Body>
          <Card.Title className='text-center fs-1 fw-bold'>Register</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label htmlFor='name'>Username:</Form.Label>
              <Form.Control
                onChange={handleInput}
                type='text'
                id='name'
                name='name'
                placeholder='Your username'
                value={values.name}
                isInvalid={!!errors.name} // Bootstrap validation
              />
              {errors.name && <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label htmlFor='email'>Email:</Form.Label>
              <Form.Control
                onChange={handleInput}
                type='email'
                id='email'
                name='email'
                placeholder='Your email'
                value={values.email}
                isInvalid={!!errors.email} // Bootstrap validation
              />
              {errors.email && <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label htmlFor='password'>Password:</Form.Label>
              <Form.Control
                onChange={handleInput}
                type='password'
                id='password'
                name='password'
                placeholder='Your password'
                value={values.password}
                isInvalid={!!errors.password} // Bootstrap validation
              />
              {errors.password && <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label htmlFor='passwordRepeat'>Repeat Password:</Form.Label>
              <Form.Control
                onChange={handleInput}
                type='password'
                id='passwordRepeat'
                name='passwordRepeat'
                placeholder='Repeat your password'
                value={values.passwordRepeat}
                isInvalid={!!errors.passwordRepeat} // Bootstrap validation
              />
              {errors.passwordRepeat && <Form.Control.Feedback type='invalid'>{errors.passwordRepeat}</Form.Control.Feedback>}
            </Form.Group>
            {errors.server && <div className='text-danger mb-3'>{errors.server}</div>}
            <Stack gap={3}>
              <Link to="">Forgot Password ?</Link>
              <Button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? 'Registering...' : 'Register'}
              </Button>
              <Link to='../login' className='text-center fs-5 fw-bold'>Already have an account?</Link>
            </Stack>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default RegisterForm;
