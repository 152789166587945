import React from 'react'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useAuth } from '../../Hooks/AuthContext';
import Button from 'react-bootstrap/esm/Button';
import { useState } from 'react';
import axios from 'axios';
import apiFinder from '../../apis/apiFinder';
const AddProducts = () => {
  const { user } = useAuth();
  const [addProducting, setAddProducting] = useState(false);
  const [errors, setErrors] = useState({});
  const [addProduct, setAddProduct] = useState({
    code: '',
    mfCode: '',
    model: '',
    title: '',
    shortTitle: '',
    brand: '',
    category: '',
    tkofCategoryId: '',
    mfModel: '',
    color: '',
    material: '',
    origin: '',
    productType: '',
    sizeChartType: '',
    sizeChartImage: '',
    sizeType: '',
    productUsage: '',
    clientProductURL: '',
    industry: '',
    publishSeason: '',
    productImages: '',
    packageImages: '',
    description: '',
    shortDescription: '',
    AttributeKey1: '',
    AttributeValue1: '',
    items: [], // 初始为空数组
  });
  const [file, setFile] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const handleUploadFile = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a file.');
      return;
    }
    if (!user) {
      console.error("user valid");
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await apiFinder.post('/uploadProducts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      console.log('Upload successful', response.data);

    } catch (error) {
      console.error('Upload failed', error);
    }
  };
  const hanldeUploadFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleAddProductChange = (e) => {
    const { name, value } = e.target;
    setAddProduct(prevProduct => ({
      ...prevProduct,
      [name]: value
    }));
  };
  const handleAddProductButton = () => {
    setAddProducting(!addProducting);
  }
  const handleAddProductToDatabase = async (product) => {
    if (validateForm()) {

      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
        const response = await apiFinder.post('/addAProductWithItems', product, config);
        if (response.status === 200) {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error('Upload failed', error);
      }
    }
  }

  const handleAddItem = () => {
    // 创建一个新的项目对象，初始化所有字段为空字符串或者默认值
    const newItem = {
      sku: '',
      size: '',
      variationValue: '',
      barcode: '',
      weight: '',
      weightUnit: '',
      dimension: '',
      currency: '',
      priceMsrp: '',
      priceCost: '',
      qty: '',
      taxRate: '',
    };
    setAddProduct(prevProduct => ({
      ...prevProduct,
      items: [...prevProduct.items, newItem]
    }));
  };
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    setAddProduct((prevProduct) => ({
      ...prevProduct,
      items: prevProduct.items.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    // Required fields validation
    const requiredFields = ['code', 'model', 'brand', 'category', 'tkofCategoryId', 'title', 'color', 'material', 'description', 'origin', 'productImages', 'productType', 'sizeType', 'industry'];
    requiredFields.forEach((field) => {
      if (!addProduct[field]) {
        newErrors[field] = 'This field is required';
      }
    });
    // Ensure there is at least one item
    if (addProduct.items.length === 0) {
      newErrors.items = 'At least one item is required';
    }
    // Item validation
    addProduct.items.forEach((item, index) => {
      if (!item.sku) {
        newErrors[`item-${index}-sku`] = 'This filed is required';
      }
      if (!item.size) {
        newErrors[`item-${index}-size`] = 'This filed is required';
      }
      if (!item.barcode) {
        newErrors[`item-${index}-barcode`] = 'This filed is required';
      }
      if (!item.weightUnit) {
        newErrors[`item-${index}-weightUnit`] = 'This filed is required';
      }
      if (item.priceMsrp && isNaN(parseFloat(item.priceMsrp))) {
        newErrors[`item-${index}-priceMsrp`] = 'Must be a valid number';
      }
      if (item.priceCost && isNaN(parseFloat(item.priceCost))) {
        newErrors[`item-${index}-priceCost`] = 'Must be a valid number';
      }
      if (item.qty && isNaN(parseFloat(item.qty))) {
        newErrors[`item-${index}-qty`] = 'Must be a valid number';
      }
      if (item.taxRate && isNaN(parseFloat(item.taxRate))) {
        newErrors[`item-${index}-taxRate`] = 'Must be a valid number';
      }
      if (item.weight && isNaN(parseFloat(item.weight))) {
        newErrors[`item-${index}-weight`] = 'Must be a valid number';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <Container fluid  >
        <div className='d-flex justify-content-center p-2'>
          <h1>Add a Product</h1>
        </div>
        <Form>
          <Row style={{ marginTop: '5px' }}>
            <h2>Add a product with an excel file</h2>
            <Col>

              <Form.Control type="file" onChange={hanldeUploadFileChange} />
            </Col>
            <Col>
              <Button type='submit' onClick={handleUploadFile}>Upload</Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <Container style={{ marginTop: '5px' }}>
        <Row>
          <h2>Add a product directly</h2>
          <Button onClick={handleAddProductButton}>{addProducting ? 'fold' : 'Add a new Product'}</Button>
        </Row>
        {addProducting &&
          <Form>
            <Row className='mb-3 '>
              <Form.Group as={Col} controlId='addcode' className='border border-danger' >
                <Form.Label>Code</Form.Label>
                <Form.Control type='text' name='code' onChange={handleAddProductChange} />
                {errors.code && <span className="text-danger">{errors.code}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addmfCode' >
                <Form.Label>MFCode</Form.Label>
                <Form.Control type='text' name='mfCode' onChange={handleAddProductChange} />

              </Form.Group>
              <Form.Group as={Col} controlId='addmodel' >
                <Form.Label>Model</Form.Label>
                <Form.Control type='text' name='model' onChange={handleAddProductChange} />
                {errors.model && <span className="text-danger">{errors.model}</span>}
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addtitle' >
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' name='title' onChange={handleAddProductChange} />
                {errors.title && <span className="text-danger">{errors.title}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addshortTitle' >
                <Form.Label>ShortTitle</Form.Label>
                <Form.Control type='text' name='shortTitle' onChange={handleAddProductChange} />

              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addbrand' >
                <Form.Label>Brand</Form.Label>
                <Form.Control type='text' name='brand' onChange={handleAddProductChange} />
                {errors.brand && <span className="text-danger">{errors.brand}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addcategory' >
                <Form.Label>Category</Form.Label>
                <Form.Control type='text' name='category' onChange={handleAddProductChange} />
                {errors.category && <span className="text-danger">{errors.category}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='tkofCategoryId' >
                <Form.Label>TKOFCategoryId</Form.Label>
                <Form.Control type='text' name='tkofCategoryId' onChange={handleAddProductChange} />
                {errors.tkofCategoryId && <span className="text-danger">{errors.tkofCategoryId}</span>}
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addmfModel' >
                <Form.Label>MFModel</Form.Label>
                <Form.Control type='text' name='mfModel' onChange={handleAddProductChange} />
              </Form.Group>
              <Form.Group as={Col} controlId='addcolor' >
                <Form.Label>Color</Form.Label>
                <Form.Control type='text' name='color' onChange={handleAddProductChange} />
                {errors.color && <span className="text-danger">{errors.color}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addmaterial' >
                <Form.Label>Material</Form.Label>
                <Form.Control type='text' name='material' onChange={handleAddProductChange} />
                {errors.material && <span className="text-danger">{errors.material}</span>}
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addorigin' >
                <Form.Label>Origin</Form.Label>
                <Form.Control type='text' name='origin' onChange={handleAddProductChange} />
                {errors.origin && <span className="text-danger">{errors.origin}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addproductType' >
                <Form.Label>ProductType</Form.Label>
                <Form.Control type='text' name='productType' onChange={handleAddProductChange} />
                {errors.productType && <span className="text-danger">{errors.productType}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addsizeChartType' >
                <Form.Label>SizeChartType</Form.Label>
                <Form.Control type='text' name='sizeChartType' onChange={handleAddProductChange} />
                {errors.sizeChartType && <span className="text-danger">{errors.sizeChartType}</span>}
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addsizeChartImage' >
                <Form.Label>SizeChartImage</Form.Label>
                <Form.Control type='text' name='sizeChartImage' onChange={handleAddProductChange} />
              </Form.Group>
              <Form.Group as={Col} controlId='addsizeType' >
                <Form.Label>SizeType</Form.Label>
                <Form.Control type='text' name='sizeType' onChange={handleAddProductChange} />
                {errors.sizeType && <span className="text-danger">{errors.sizeType}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addproductUsage' >
                <Form.Label>Usage</Form.Label>
                <Form.Control type='text' name='productUsage' onChange={handleAddProductChange} />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addclientProductURL' >
                <Form.Label>ClientProductURL</Form.Label>
                <Form.Control type='text' name='clientProductURL' onChange={handleAddProductChange} />
              </Form.Group>
              <Form.Group as={Col} controlId='addindustry' >
                <Form.Label>Industry</Form.Label>
                <Form.Control type='text' name='industry' onChange={handleAddProductChange} />
              </Form.Group>
              <Form.Group as={Col} controlId='publishSeason' >
                <Form.Label>PublishSeason</Form.Label>
                <Form.Control type='text' name='publishSeason' onChange={handleAddProductChange} />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='addproductImages' >
                <Form.Label>ProductImages</Form.Label>
                <Form.Control as='textarea' rows={3} name='productImages' onChange={handleAddProductChange} />
                {errors.productImages && <span className="text-danger">{errors.productImages}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addpackageImages' >
                <Form.Label>PackageImages</Form.Label>
                <Form.Control as='textarea' rows={3} name='packageImages' onChange={handleAddProductChange} />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='adddescription' >
                <Form.Label>description</Form.Label>
                <Form.Control as='textarea' rows={3} name='description' onChange={handleAddProductChange} />
                {errors.description && <span className="text-danger">{errors.description}</span>}
              </Form.Group>
              <Form.Group as={Col} controlId='addshortDescription' >
                <Form.Label>shortDescription</Form.Label>
                <Form.Control as='textarea' rows={3} name='shortDescription' onChange={handleAddProductChange} />
              </Form.Group>
            </Row>
            <Row className='mb-3 border border-danger'>
              <Form.Group as={Col} controlId='addAttributeKey1' >
                <Form.Label>AttributeKey1</Form.Label>
                <Form.Control type='text' name='AttributeKey1' onChange={handleAddProductChange} />
              </Form.Group>
              <Form.Group as={Col} controlId='addAttributeValue1' >
                <Form.Label>AttributeValue1</Form.Label>
                <Form.Control type='text' name='AttributeValue1' onChange={handleAddProductChange} />
              </Form.Group>
            </Row>

            {addProduct.items.map((item, index) => (
              <React.Fragment key={index}>
                {/* 动态创建每个项目的表单元素 */}
                <Row><span>item</span> </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} controlId={`sku-${index}`} >
                    <Form.Label>Sku</Form.Label>
                    <Form.Control type='text' name='sku' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-sku`] && <span className="text-danger">{errors[`item-${index}-sku`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`barcode-${index}`} >
                    <Form.Label>barcode</Form.Label>
                    <Form.Control type='text' name='barcode' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-barcode`] && <span className="text-danger">{errors[`item-${index}-barcode`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`mfBarcode-${index}`} >
                    <Form.Label>mfBarcode</Form.Label>
                    <Form.Control type='text' name='mfBarcode' onChange={e => handleItemChange(index, e)} />
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} controlId={`size-${index}`}>
                    <Form.Label>Size</Form.Label>
                    <Form.Control type='text' name='size' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-size`] && <span className="text-danger">{errors[`item-${index}-size`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`variationValue-${index}`} >
                    <Form.Label>VariationValue</Form.Label>
                    <Form.Control type='text' name='variationValue' onChange={e => handleItemChange(index, e)} />
                  </Form.Group>
                  <Form.Group as={Col} controlId={`dimension-${index}`} >
                    <Form.Label>Dimension</Form.Label>
                    <Form.Control type='text' name='dimension' onChange={e => handleItemChange(index, e)} />
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} controlId={`weight-${index}`} >
                    <Form.Label>Weight</Form.Label>
                    <Form.Control type='text' name='weight' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-weight`] && <span className="text-danger">{errors[`item-${index}-weight`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`weightUnit-${index}`} >
                    <Form.Label>WeightUnit</Form.Label>
                    <Form.Control type='text' name='weightUnit' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-weightUnit`] && <span className="text-danger">{errors[`item-${index}-weightUnit`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`qty-${index}`} >
                    <Form.Label>qty</Form.Label>
                    <Form.Control type='text' name='qty' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-qty`] && <span className="text-danger">{errors[`item-${index}-qty`]}</span>}
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} controlId={`currency-${index}`} >
                    <Form.Label>currency</Form.Label>
                    <Form.Control type='text' name='currency' onChange={e => handleItemChange(index, e)} />
                  </Form.Group>
                  <Form.Group as={Col} controlId={`priceMsrp-${index}`} >
                    <Form.Label>PriceMsrp</Form.Label>
                    <Form.Control type='text' name='priceMsrp' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-priceMsrp`] && <span className="text-danger">{errors[`item-${index}-priceMsrp`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`proceCost-${index}`} >
                    <Form.Label>PriceCost</Form.Label>
                    <Form.Control type='text' name='priceCost' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-priceCost`] && <span className="text-danger">{errors[`item-${index}-priceCost`]}</span>}
                  </Form.Group>
                  <Form.Group as={Col} controlId={`taxRate-${index}`} >
                    <Form.Label>TaxRate</Form.Label>
                    <Form.Control type='text' name='taxRate' onChange={e => handleItemChange(index, e)} />
                    {errors[`item-${index}-taxRate`] && <span className="text-danger">{errors[`item-${index}-taxRate`]}</span>}
                  </Form.Group>
                </Row>
              </React.Fragment>
            ))}
            <Button onClick={handleAddItem}>Add new Item</Button>
            {errors.items && <span className="text-danger">{errors.items}</span>}
            <Button style={{ width: '100%', position: 'sticky', bottom: '60px' }} onClick={() => handleAddProductToDatabase(addProduct)}>add product</Button>
          </Form>}

      </Container>
    </>
  )
}

export default AddProducts
