import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Card } from 'react-bootstrap';

const ResetPassword = () => {
    const { token } = useParams(); // Get the token from the URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
   

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if passwords match
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
            setSuccessMessage(null);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${window.baseURL}/reset-password/${token}`, {
                password: newPassword, // Send the new password with the correct key
            });
            setSuccessMessage(response.data.message); // Assuming the response has a message field
            setError(null);
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (err) {
            setError(err.response ? err.response.data.message : 'Failed to reset password');
            setSuccessMessage(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
            <h1 className='text-center text-light fs-1 fw-bold'>EV2L TRADE</h1>
            <Card className='shadow-sm p-4' style={{ width: '400px' }}>
                <Card.Body>
                    <Card.Title className='text-center fs-1 fw-bold'>Reset Password</Card.Title>
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-3' controlId='formNewPassword'>
                            <Form.Label className='fs-4 fw-bold'>New Password:</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Enter new password'
                                value={newPassword}
                                className='border border-secondary'
                                onChange={e => setNewPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formConfirmPassword'>
                            <Form.Label className='fs-4 fw-bold'>Confirm Password:</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Confirm new password'
                                value={confirmPassword}
                                className='border border-secondary'
                                onChange={e => setConfirmPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button type='submit' className='w-100 text-center fs-4 fw-bold' disabled={loading}>
                            {loading ? 'Resetting...' : 'Reset Password'}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ResetPassword;
