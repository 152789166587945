import React,{useState} from 'react';
import Layout from '../components/Basic/Layout';
import ViewMoreProduct from '../components/Products/ViewMoreProduct';
const ViewProductPage = () => {
    return (
    <Layout><ViewMoreProduct/></Layout>
    )

}

export default ViewProductPage
