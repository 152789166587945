import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'; // Import Alert component
import { useNavigate } from 'react-router-dom';
import Validation from '../Register/RegisterValidation';
import axios from 'axios';

const AddUser = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    passwordRepeat: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const navigate = useNavigate();

  const handleInput = (event) => {
    setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = Validation(values);
    console.log("Form values before validation:", values);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      setSuccessMessage(''); // Reset success message
      try {
        const res = await axios.post(`${window.baseURL}/register`, {
          username: values.name,
          email: values.email,
          password: values.password
        });
        console.log(res);
        setSuccessMessage('User successfully added!'); // Set success message
        setTimeout(() => {
          navigate('/addUser'); // Navigate after a short delay
        }, 2000);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          setErrors({ server: err.response.data.message });
        } else {
          setErrors({ server: "Registration failed. Please try again." });
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Validation errors:", validationErrors);
      setErrors(validationErrors);
    }
  };

  return (
    <Container className='d-flex flex-column mb-3 align-items-center justify-content-center'>
      <Button onClick={() => navigate('/manageUsers')}>Back</Button>
      <p className='text-center fs-1 fw-bold'>Add a user</p>
      <div className='w-100 shadow-sm p-3'>
        {successMessage && <Alert variant='success'>{successMessage}</Alert>} {/* Success alert */}
        {errors.server && <Alert variant='danger'>{errors.server}</Alert>} {/* Server error alert */}
        <Form action='' onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='name' className="form-label">Username:</Form.Label>
            <Form.Control
              onChange={handleInput}
              type='text'
              className='form-control'
              id='name'
              name='name'
              placeholder='Input a username'
              value={values.name}
            />
            {errors.name && <span className='text-danger'>{errors.name}</span>}
          </Form.Group>
          <div className='mb-3'>
            <Form.Label htmlFor='email' className="form-label">Email:</Form.Label>
            <Form.Control
              onChange={handleInput}
              type='email'
              className='form-control'
              id='email'
              name='email'
              placeholder='Input an email'
              value={values.email}
            />
            {errors.email && <span className='text-danger'>{errors.email}</span>}
          </div>
          <div className='mb-3'>
            <Form.Label htmlFor='password' className="form-label">Password:</Form.Label>
            <div>
              <Form.Control
                onChange={handleInput}
                type='password'
                className='form-control'
                id='password'
                name='password'
                placeholder='Input a password'
                value={values.password}
              />
              {errors.password && <span className='text-danger'>{errors.password}</span>}
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='passwordRepeat' className="form-label">Repeat Password:</label>
            <div>
              <Form.Control
                onChange={handleInput}
                type='password'
                className='form-control'
                id='passwordRepeat'
                name='passwordRepeat'
                placeholder='Repeat the password'
                value={values.passwordRepeat}
              />
              {errors.passwordRepeat && <span className='text-danger'>{errors.passwordRepeat}</span>}
            </div>
          </div>
          <Stack gap={3}>
            <Button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? 'Registering...' : 'Register'}
            </Button>
          </Stack>
        </Form>
      </div>
    </Container>
  );
};

export default AddUser;
