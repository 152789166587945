import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import UpdatePrice from '../components/Items/UpdatePrice';
const ItemPricePage = () => {
  useEffect(() => {
    document.title = "Update price";
  }, []);
  return (
   <Layout>
    <UpdatePrice/>
   </Layout>
  )
}

export default ItemPricePage
