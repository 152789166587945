import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import apiFinder from '../../apis/apiFinder';

const CancelRequestOrders = () => {
  const [cancelRequestUserOrders, setCancelRequestUserOrders] = useState([]);
  const [cancelRequestOrders, setCancelRequestOrders] = useState([]);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ordersLoading, setOrdersLoading] = useState(true); // Loading state for orders

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');

        // Fetch user data
        const userResponse = await axios.get(`${window.baseURL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(userResponse.data.user);

        // Fetch orders based on user role
        if (userResponse.data.user.role === 'admin') {
          setOrdersLoading(true); // Set loading for orders
          const status = "CancelRequest";
          const ordersResponse = await apiFinder.post("/api/getOrdersByStatus", { status }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          if (ordersResponse.data.success) {
            setCancelRequestOrders(ordersResponse.data.orders);
          }
        } else {
          const userOrdersResponse = await axios.get(`${window.baseURL}/cancelrequestorders`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setCancelRequestUserOrders(userOrdersResponse.data.orders);
        }
      } catch (err) {
        console.error(err);
        setError(err.response ? err.response.data.message : 'Failed to fetch data');
      } finally {
        setLoading(false); // Set loading to false after fetching data
        setOrdersLoading(false); // Set orders loading to false
      }
    };
    fetchData();
  }, []);

  const handleCancelRequest = async (order) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      const response = await apiFinder.post("/api/handleCancelRequest", { order }, config);
      if (response.data.success) {
        console.log(response.data.message);
        // Optionally, you can refresh the orders or update the UI here
      }
    } catch (error) {
      console.log("Acknowledging cancel orders failed, try again");
    }
  };

  if (loading) {
    return <Container fluid><p>Loading user data...</p></Container>;
  }

  if (error) {
    return <Container fluid><p>{error}</p></Container>;
  }

  return (
    <Container fluid>
      {userData?.role === 'admin' ? (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Order:</th>
              <th>OrderId</th>
              <th>OrderTime</th>
              <th>platformId</th>
              <th>cartId</th>
              <th>cartOrderId</th>
              <th>orderStatus</th>
              <th>fromWarehouseKey</th>
              <th>platformCurrency</th>
              <th>platformAmount</th>
              <th>platformDiscount</th>
              <th>platformTax</th>
              <th>platformShippingFee</th>
              <th>exchangeRate</th>
              <th>currency</th>
              <th>amount</th>
              <th>discount</th>
              <th>tax</th>
              <th>shippingFee</th>
            </tr>
          </thead>
          <tbody>
            {ordersLoading ? (
              <tr>
                <td colSpan="18">Loading Cancel Request Orders...</td>
              </tr>
            ) : cancelRequestOrders.length > 0 ? (
              cancelRequestOrders.map((order, orderIndex) => (
                <React.Fragment key={`cancelRequestOrders-fragment-${orderIndex}`}>
                  <tr>
                    <td>{orderIndex + 1}</td>
                    <td>{order.orderId}</td>
                    <td>{order.orderTime}</td>
                    <td>{order.platformId}</td>
                    <td>{order.cartId}</td>
                    <td>{order.cartOrderId}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.fromWarehouseKey}</td>
                    <td>{order.platformCurrency}</td>
                    <td>{order.platformAmount}</td>
                    <td>{order.platformDiscount}</td>
                    <td>{order.platformTax}</td>
                    <td>{order.platformShippingFee}</td>
                    <td>{order.exchangeRate}</td>
                    <td>{order.currency}</td>
                    <td>{order.amount}</td>
                    <td>{order.discount}</td>
                    <td>{order.tax}</td>
                    <td>{order.shippingFee}</td>
                  </tr>
                  <tr>
                    <th>Items:</th>
                    <th>sku</th>
                    <th>code</th>
                    <th>model</th>
                    <th>title</th>
                    <th>qty</th>
                    <th>platformUnitPrice</th>
                    <th>platformAmount</th>
                    <th>platformTax</th>
                    <th>platformDiscount</th>
                    <th>platformShippingFee</th>
                    <th>cost</th>
                    <th>unitPrice</th>
                    <th>amount</th>
                    <th>tax</th>
                    <th>discount</th>
                    <th>shippingFee</th>
                  </tr>
                  {order.items?.length > 0 ? (
                    order.items.map((item, itemIndex) => (
                      <tr key={`cancelRequestOrderItem-${itemIndex}`}>
                        <td></td>
                        <td>{item.sku}</td>
                        <td>{item.code}</td>
                        <td>{item.model}</td>
                        <td>{item.title}</td>
                        <td>{item.qty}</td>
                        <td>{item.platformUnitPrice}</td>
                        <td>{item.platformAmount}</td>
                        <td>{item.platformTax}</td>
                        <td>{item.platformDiscount}</td>
                        <td>{item.platformShippingFee}</td>
                        <td>{item.cost}</td>
                        <td>{item.unitPrice}</td>
                        <td>{item.amount}</td>
                        <td>{item.tax}</td>
                        <td>{item.discount}</td>
                        <td>{item.shippingFee}</td>
                      </tr>
                    ))
                  ) : (
                    <tr><td colSpan="3">No items found.</td></tr>
                  )}
                  <tr>
                    <td colSpan="3">
                      <Button onClick={() => handleCancelRequest(order)}>Ack Cancel order: {order.orderId}</Button>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="18">No Cancel Request Orders found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <Container>
          <Table>
            <thead>
              <tr>
                <th>Order:</th>
                <th>OrderId</th>
                <th>OrderTime</th>
                <th>platformId</th>
                <th>cartId</th>
                <th>cartOrderId</th>
                <th>orderStatus</th>
                <th>fromWarehouseKey</th>
                <th>platformCurrency</th>
                <th>platformAmount</th>
                <th>platformDiscount</th>
                <th>platformTax</th>
                <th>platformShippingFee</th>
                <th>exchangeRate</th>
                <th>currency</th>
                <th>amount</th>
                <th>discount</th>
                <th>tax</th>
                <th>shippingFee</th>
              </tr>
            </thead>
            <tbody>
              {cancelRequestUserOrders.length > 0 ? (
                cancelRequestUserOrders.map((order, orderIndex) => (
                  <React.Fragment key={`cancelRequestOrders-fragment-${orderIndex}`}>
                    <tr>
                      <td>{orderIndex + 1}</td>
                      <td>{order.orderId}</td>
                      <td>{order.orderTime}</td>
                      <td>{order.platformId}</td>
                      <td>{order.cartId}</td>
                      <td>{order.cartOrderId}</td>
                      <td>{order.orderStatus}</td>
                      <td>{order.fromWarehouseKey}</td>
                      <td>{order.platformCurrency}</td>
                      <td>{order.platformAmount}</td>
                      <td>{order.platformDiscount}</td>
                      <td>{order.platformTax}</td>
                      <td>{order.platformShippingFee}</td>
                      <td>{order.exchangeRate}</td>
                      <td>{order.currency}</td>
                      <td>{order.amount}</td>
                      <td>{order.discount}</td>
                      <td>{order.tax}</td>
                      <td>{order.shippingFee}</td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="18">No orders found.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      )}
    </Container>
  );
};

export default CancelRequestOrders;
