import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import UpdateQuantity from '../components/Items/UpdateQuantity';

const ItemQuantityPage = () => {
  useEffect(() => {
    document.title = "Update quantity";
  }, []);
  return (
    <Layout>
        <UpdateQuantity/>
    </Layout>
  )
}

export default ItemQuantityPage
