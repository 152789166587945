// RegisterValidation.js
const Validation = (values) => {
    let errors = {};
  
    // Check for empty fields
    if (!values.name.trim()) {
      errors.name = "Username is required";
    }
  
    if (!values.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }
  
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
  
    if (!values.passwordRepeat) {
      errors.passwordRepeat = "Please repeat your password";
    } else if (values.password !== values.passwordRepeat) {
      errors.passwordRepeat = "Passwords do not match";
    }
  
    return errors;
  };
  
  export default Validation;
  