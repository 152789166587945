import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useAuth } from '../../Hooks/AuthContext';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap';
import axios from 'axios'; // Import Axios

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleForgotPassword = async (forgotPasswordEmail) => {
    try {
        const response = await axios.post('http://localhost:3301/api/v1/ev2ltrade/reset-password', { email: forgotPasswordEmail });
        console.log('Response:', response);
        if (response.status === 200) {
            setSuccessMessage('A password reset email has been sent to your email address.');
            setError(null);
            setShowForgotPasswordModal(false); // Close the modal
        } else {
            setError(response.data.message || 'Failed to send password reset email. Please try again later.');
            setSuccessMessage(null);
        }
    } catch (err) {
        console.error('Error:', err); // Log the error
        setError(err.response ? err.response.data.message : 'Failed to send password reset email. Please try again later.');
        setSuccessMessage(null);
    }
};


  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null); // Reset error state
    try {
      await login({ email, password });
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }
    } catch (err) {
      console.error('Login error:', err); // Log the error
      setError(err.message || 'Login failed. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };


  const ContactUsModal = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Get in Touch</h5>
          <p>If you have any questions, feel free to reach out to us:</p>
          <p>Email: <a href="mailto:support@example.com">support@example.com</a></p>
          <p>Phone: <a href="tel:+1234567890">+1 (234) 567-890</a></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ForgotPasswordModal = ({ show, handleClose }) => {
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState(''); // Local state for email input

    const handleForgotPasswordSubmit = (e) => {
      e.preventDefault();
      handleForgotPassword(forgotPasswordEmail);
      setForgotPasswordEmail(''); // Clear the email input after submission
    };

    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleForgotPasswordSubmit}>
            <Form.Group className='mb-3' controlId='formForgotPasswordEmail'>
              <Form.Label className='fs-4 fw-bold'>Email:</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter your email'
                value={forgotPasswordEmail}
                className='border border-secondary'
                onChange={e => setForgotPasswordEmail(e.target.value)}
                required
              />
            </Form.Group>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            <Button type='submit' className='w-100'>Send Reset Link</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
      <h1 className='text-center text-light fs-1 fw-bold'>EV2L TRADE</h1>
      <Card className='shadow-sm p-4' style={{ width: '400px' }}>
        <Card.Body>
          <Card.Title className='text-center fs-1 fw-bold'>Login</Card.Title>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='formLoginEmail'>
              <Form.Label className='fs-4 fw-bold'>Email:</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                className='border border-secondary'
                onChange={e => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formLoginPassword'>
              <Form.Label className='fs-4 fw-bold'>Password:</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                className='border border-secondary'
                onChange={e => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Remember me"
                className='text-start fs-6 fw-bold'
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
            </Form.Group>
            <Stack gap={3}>
              <Button type='submit' className='text-center fs-4 fw-bold' disabled={loading}>{loading ? 'Logging in...' : 'Login'}</Button>

              <p className='text-center text-primary fs-5 fw-bold text-decoration-underline' onClick={() => setShowForgotPasswordModal(true)} style={{ cursor: 'pointer' }}>Forgot Password?</p>
              <Link to='../register' className='align-self-center text-center fs-5 fw-bold'>Don't have an account yet?</Link>
              <p className='text-center text-primary fs-5 fw-bold text-decoration-underline' onClick={() => setShowContactModal(true)} style={{ cursor: 'pointer' }}>Contact us</p>
            </Stack>
          </Form>
        </Card.Body>
      </Card>

      <ContactUsModal show={showContactModal} handleClose={() => setShowContactModal(false)} />
      <ForgotPasswordModal show={showForgotPasswordModal} handleClose={() => setShowForgotPasswordModal(false)} />
    </Container>
  );
};

export default LoginForm;
