import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import ViewProducts from '../components/Products/ViewProducts';
const EditProductsPage = () => {
  useEffect(() => {
    document.title = "Products";
  }, []);
  return (
   <Layout>
    <ViewProducts/>
    </Layout>
  )
  
}

export default EditProductsPage;
