import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const UploadItemsApi = ({apiProducts,getApiProducts }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedItems,setSelectedItems]=useState([]);
    const skuMap = new Map();
    if (getApiProducts) {
        getApiProducts.forEach(product => {
            product.items.forEach(item => {
                const extendedItem = {
                    ...item,
                    productCode: product.code
                };
                skuMap.set(item.sku, extendedItem);  // 直接存储单个 item 而不是数组
            });
        });
    }
    // 处理复选框更改
    const handleCheckboxChange = (identifier, isChecked) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [identifier]: isChecked
        }));
    };
     // 获取所有选中的项
     const getSelectedItems = () => {
        const selectedItems = apiProducts.flatMap(product =>
            product.items.filter(item => checkedItems[`${item.productCode}-${item.barcode}`])
        );
        return selectedItems.map(item=>({
            code:item.productCode,
            sku:item.sku,
            barcode:item.barcode,
            mfBarcode:item.mfBarcode,
            size:item.size,
            variationValue:item.variationValue,
            weight:item.weight,
            weightUnit:item.weightUnit,
            dimension:item.dimension,
            currency:item.currency,
            priceMsrp:item.priceMsrp,
            priceCost:item.priceCost,
            priceRetail:item.priceMsrp,
            saleCurrency:item.currency,
            saleMsrp: item.priceMsrp,
            saleCost: item.priceCost,
            saleRetail: item.priceMsrp,
            qty:item.qty,
            taxrate:item.taxRate,
            alive:item.alive
        // console.log(uploadData);
        }));
       // setSelectedItems(uploadData);
    };

const handleUploadPrice =async() => {
    try{
       const select= getSelectedItems();
        console.log(select);
        const config={
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            },
          }
          const response = await axios.post('http://localhost:3301/api/uploadItems', select,config);
          if (response.status === 200&&response.data.success) {
            // 处理成功保存数据的情况
            console.log(response.data.results);
            console.log('Product upload price successfully');
  
          } else {
            // 处理无法保存数据的情况
            console.log(response.data.message);
            console.error('Failed to update');
          }
    }catch(error){
        console.error('Error upload price :', error); 
    }
}
function formatTimeDifference(updatedAtString) {
    const updatedAt = new Date(updatedAtString);
    const now = new Date();
    const diff = now - updatedAt; // 毫秒差

    const diffInMinutes = Math.floor(diff / (1000 * 60)); // 转换为分钟
    const minutes = diffInMinutes % 60;
    const diffInHours = Math.floor(diffInMinutes / 60);
    const hours = diffInHours % 24;
    const days = Math.floor(diffInHours / 24);

    return `${days}Days${hours}Hours${minutes}Minutes Before`;
}
  return (
   <Container fluid>
   <Table hover responsive>
   <thead>
      <tr>
        <th>#</th>
        <th>Code</th>
        <th>Sku</th>
        <th>Barcode</th>
        <th>mfBarcode</th>
        <th>Size</th>
        <th>VariationValue</th>
        <th>weight</th>
        <th>weightUnit</th>
        <th>dimension</th>
        <th>currency</th>
        <th>priceMsrp</th>
        <th>priceCost</th>
        <th>Quantity</th>
        <th>taxRate</th>
        <th>alive</th>
        
      </tr>
    </thead>
     <tbody > 
{apiProducts ? (
apiProducts.map((product, productIndex) => (
    <React.Fragment key={`item-fragment-${product.code}-${productIndex}`}>
   <tr key={`productItems-${product.code}`}>
     <td>{productIndex + 1}</td>
    <td>{product.code}</td>
   </tr>
   {product.items && product.items.length > 0 ? (
     product.items.map((item, itemIndex) => {
       const matchedProduct = skuMap.get(item.sku) || []; 
      return (
        <React.Fragment key={`item-fragment-${item.itemsId}`}>
       <tr key={`productPriceItem-${itemIndex}`} >
       <td ><Form.Check  type='checkbox'  id={`ProductItemsCheck-${item.productCode}-${item.barcode}`} checked={!!checkedItems[`${item.productCode}-${item.barcode}`]}
        onChange={e => handleCheckboxChange(`${item.productCode}-${item.barcode}`, e.target.checked)} /></td>
         <td >{item.productCode}</td>
         <td>{item.sku}</td>
        <td>{item.barcode}</td>
        <td>{item.mfBarcode}</td>
        <td>{item.size}</td>
        <td>{item.variationValue}</td>
        <td>{item.weight}</td>
        <td>{item.weightUnit}</td>
        <td>{item.dimension}</td>
        <td>{item.currency}</td>
        <td>{item.priceMsrp}</td>
        <td>{item.priceCost}</td>
        <td>{item.qty}</td>
        <td>{item.taxRate}</td>
        <td>{item.alive ? <span>True</span> : <span>False</span>}</td>
         <td>{formatTimeDifference(item.updatedAt)}</td>
       </tr>
       <tr key={`apiProductPriceItem-${matchedProduct.code}`} className="table table-primary">
           <td>API</td>
           <td>{matchedProduct.productCode}</td>
           <td>{matchedProduct.sku}</td>
           <td>{matchedProduct.barcode}</td>
           <td>{matchedProduct.mfBarcode}</td>
           <td>{matchedProduct.size}</td>
           <td>{matchedProduct.variationValue}</td>
            <td>{matchedProduct.weight}</td>
            <td>{matchedProduct.weightUnit}</td>
            <td>{matchedProduct.dimension}</td>
            <td>{matchedProduct.currency}</td>
            <td>{matchedProduct.priceMsrp}</td>
            <td>{matchedProduct.priceCost}</td>
            <td>{matchedProduct.qty}</td>
            <td>{matchedProduct.taxRate}</td>
            <td>{matchedProduct.alive ? <span>True</span> : <span>False</span>}</td>
       </tr>
       </React.Fragment>
     )
     })
   ) : (
     <tr >
       <td colSpan="3">Item loading...</td>
     </tr>
   )}
   <tr>

   </tr>

 </React.Fragment>
 
))
) : (
<tr key={`loadingProducts`}>
 <td colSpan="3">Products loading...</td>
</tr>
)}
</tbody>
</Table>
<Button variant='success' className='text-center' style={{width: '100%',position:'sticky',bottom:'60px'}} onClick={handleUploadPrice } >Selected Upload </Button> 
</Container>
  )
}

export default UploadItemsApi
