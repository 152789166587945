import React from 'react';
import { useEffect,useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import apiFinder from '../../apis/apiFinder';

const CompleteOrders = () => {
  //user
    const [completeOrders,setCompleteOrders]=useState(null);
  //admin
    const [completeUserOrders, setCompleteUserOrders] = useState(null);
    const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${window.baseURL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data); // Log the response data
        setUserData(response.data.user); // Access user data correctly
      } catch (err) {
        console.error(err); // Log the error
        setError(err.response ? err.response.data.message : 'Failed to fetch user data');
      }
    };
    fetchUserData();
  }, []);

    useEffect(()=>{
        const fetchOrders = async () => {
          try {
            const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
              },
            };
            const status="Complete";
            const response = await apiFinder.post("/api/getOrdersByStatus",{status}, config);
            if (response.data.success) {
             setCompleteOrders(response.data.orders);
            }
          } catch (error) {
            console.log("Get orders failed, try again");
          }
        };
        fetchOrders();
      },[]);

      useEffect(() => {
        const fetchUserOrders = async () => {
          try {
            const token = sessionStorage.getItem('token');
            const response = await axios.get(`${window.baseURL}/completeorders`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            console.log(response.data); // Log the response data
            setCompleteUserOrders(response.data.orders);
            console.log('Complete user orders:', response.data.orders);
          } catch (err) {
            console.error(err); // Log the error
            setError(err.response ? err.response.data.message : 'Failed to fetch completed orders');
          }
        };
        fetchUserOrders();
      }, []);

      if (error) {
        return <Container fluid><p>{error}</p></Container>;
      }

  return (
    <Container fluid>
     {userData && userData.role === 'admin' ? (
    <Table responsive hover>
    <thead >
      <tr >
      <th>Order:</th>
      <th>OrderId</th>
      <th>OrderTime</th>
      <th>platformId</th>
      <th>cartId</th>
      <th>cartOrderId</th>
      <th>orderStatus</th>
      <th>fromWarehouseKey</th>
      <th>platformCurrency</th>
      <th>platformAmount</th>
      <th>platformDiscount</th>
      <th>platformTax</th>
      <th>platformShippingFee</th>
      <th>exchangeRate</th>
      <th>currency</th>
      <th>amount</th>
      <th>discount</th>
      <th>tax</th>
      <th>shippingFee</th>
      </tr>
    </thead>
    <tbody>
    {completeOrders ? completeOrders.map((order,orderIndex) => {
      return(
        <React.Fragment key={`completeOrders-fragment-${orderIndex}`}>
           <tr key={`completeOrder-${orderIndex}`}>
            <td>{orderIndex+1}</td>
            <td>{order.orderId}</td>
            <td>{order.orderTime}</td>
            <td>{order.platformId}</td>
            <td>{order.cartId}</td>
            <td>{order.cartOrderId}</td>
            <td>{order.orderStatus}</td>
            <td>{order.fromWarehouseKey}</td>
            <td>{order.platformCurrency}</td>
            <td>{order.platformAmount}</td>
            <td>{order.platformDiscount}</td>
            <td>{order.platformTax}</td>
            <td>{order.platformShippingFee}</td>
            <td>{order.exchangeRate}</td>
            <td>{order.currency}</td>
            <td>{order.amount}</td>
            <td>{order.discount}</td>
            <td>{order.tax}</td>
            <td>{order.shippingFee}</td>
           </tr>
           <tr>
            <th>Items:</th>
            <th>sku</th>
            <th>code</th>
            <th>model</th>
            <th>title</th>
            <th>qty</th>
            <th>platformUnitPrice</th>
            <th>platformAmount</th>
            <th>platformTax</th>
            <th>platformDiscount</th>
            <th>platformShippingFee</th>
            <th>cost</th>
            <th>unitPrice</th>
            <th>amount</th>
            <th>tax</th>
            <th>discount</th>
            <th>shippingFee</th>
           </tr>
          {order.items ? (order.items.map((item,itemIndex)=>(
            <tr key={`completeOrderItem-${itemIndex}`}>
              <td></td>
              <td>{item.sku}</td>
              <td>{item.code}</td>
              <td>{item.model}</td>
              <td>{item.title}</td>
              <td>{item.qty}</td>
              <td>{item.platformUnitPrice}</td>
              <td>{item.platformAmount}</td>
              <td>{item.platformTax}</td>
              <td>{item.platformDiscount}</td>
              <td>{item.platformShippingFee}</td>
              <td>{item.cost}</td>
              <td>{item.unitPrice}</td>
              <td>{item.amount}</td>
              <td>{item.tax}</td>
              <td>{item.discount}</td>
              <td>{item.shippingFee}</td>
            </tr>
          ))
          ):(<tr><td colSpan="3">Items loading...</td></tr>)}

       </React.Fragment>
      )
    }) : (
      <tr >
      <td colSpan="3">Orders loading...</td>
      </tr>
      )}
    </tbody>
    </Table>
     ):(
      <Table>
          <thead>
            <tr>
              <th>Order:</th>
              <th>OrderId</th>
              <th>OrderTime</th>
              <th>platformId</th>
              <th>cartId</th>
              <th>cartOrderId</th>
              <th>orderStatus</th>
              <th>fromWarehouseKey</th>
              <th>platformCurrency</th>
              <th>platformAmount</th>
              <th>platformDiscount</th>
              <th>platformTax</th>
              <th>platformShippingFee</th>
              <th>exchangeRate</th>
              <th>currency</th>
              <th>amount</th>
              <th>discount</th>
              <th>tax</th>
              <th>shippingFee</th>
            </tr>
          </thead>
          <tbody>
          {completeUserOrders ? completeUserOrders.map((order, orderIndex) => (
                <React.Fragment key={`completeOrders-fragment-${orderIndex}`}>
                  <tr key={`completeOrder-${orderIndex}`}>
                    <td>{orderIndex + 1}</td>
                  <td>{order.orderId}</td>
                  <td>{order.orderTime}</td>
                  <td>{order.platformId}</td>
                  <td>{order.cartId}</td>
                  <td>{order.cartOrderId}</td>
                  <td>{order.orderStatus}</td>
                  <td>{order.fromWarehouseKey}</td>
                  <td>{order.platformCurrency}</td>
                  <td>{order.platformAmount}</td>
                  <td>{order.platformDiscount}</td>
                  <td>{order.platformTax}</td>
                  <td>{order.platformShippingFee}</td>
                  <td>{order.exchangeRate}</td>
                  <td>{order.currency}</td>
                  <td>{order.amount}</td>
                  <td>{order.discount}</td>
                  <td>{order.tax}</td>
                  <td>{order.shippingFee}</td>
                </tr>
                </React.Fragment>
              )) : (
              <tr>
                <td colSpan="18">No orders found.</td>
              </tr>
            )}
          </tbody>
        </Table>
     )}
    </Container>
  )
}

export default CompleteOrders
