import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import EditUserProfile from '../components/Users/EditUserProfile';
const EditUserProfilePage = () => {
  useEffect(() => {
    document.title = "Profile";
  }, []);
  return (
   <Layout>
    <EditUserProfile/>
    </Layout>
  )
  
}

export default EditUserProfilePage;