import React, { useEffect } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import Layout from '../components/Basic/Layout';

const HomePage = () => {
  useEffect(() => {
    document.title = "Home";
  }, []);
  return(
    <>
     <Layout>
   <Dashboard/>
  </Layout>
    </>
 
  )
}

export default HomePage;
