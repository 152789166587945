import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import './Leftbar.css';
import { useNavigate } from 'react-router-dom';
import {
    UilCreateDashboard,
    UilFileCheckAlt,
    UilFilePlusAlt,
    UilFileSearchAlt,
    UilFileRedoAlt,
    UilArrowsShrinkH,
    UilArrowsMerge,
    UilCloudRedo,
    UilFileContractDollar,
    UilFileQuestionAlt,
} from "@iconscout/react-unicons";

const withDefaultParams = (IconComponent) => ({ size = 24, color = '#000000', ...props }) => {
    return <IconComponent size={size} color={color} {...props} />;
};

const DashboardIcon = withDefaultParams(UilCreateDashboard);
const OrdersIcon = withDefaultParams(UilFileCheckAlt);
const AddOrdersIcon = withDefaultParams(UilFilePlusAlt);
const ProductsIcon = withDefaultParams(UilFileSearchAlt);
const UpdateItemsIcon = withDefaultParams(UilFileRedoAlt);
const ExtendIcon = withDefaultParams(UilArrowsShrinkH);
const MergeIcon = withDefaultParams(UilArrowsMerge);
const UploadApiIcon = withDefaultParams(UilCloudRedo);
const UpdatePriceIcon = withDefaultParams(UilFileContractDollar);
const UpdateInventoryIcon = withDefaultParams(UilFileQuestionAlt);

const Leftbar = ({ navExpanded, setNavExpanded }) => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);

    const navLinks = [
        { path: "/home", label: "DashBoard", icon: <DashboardIcon /> },
        { path: "/editproducts", label: "Products", icon: <ProductsIcon /> },
        { path: "/addProducts", label: "Add Products", icon: <AddOrdersIcon /> },
        { path: "/orders", label: "Orders", icon: <OrdersIcon /> },
        { path: "/updateItems", label: "Update Items", icon: <UpdateItemsIcon /> },
        { path: "/updatePrice", label: "Update Price", icon: <UpdatePriceIcon /> },
        { path: "/updateQuantity", label: "Update Quantity", icon: <UpdateInventoryIcon /> },
        { path: "/uploadApi", label: "Upload API", icon: <UploadApiIcon /> },
        { path: "/changesHistory", label: "Show History", icon: <UploadApiIcon /> },
    ];

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsVisible(false); // Hide the sidebar
        } else {
            setIsVisible(true); // Show the sidebar
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Call it once to set the initial state

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Container className={`leftbar ${navExpanded ? 'expanded' : 'retracted'} ${!isVisible ? 'hidden' : ''}`} style={{ height: '100%' }}>
            <Button 
                onClick={() => setNavExpanded(!navExpanded)} 
                className={`mb-3 ${navExpanded ? 'float-end' : 'float-start'}`} 
                variant='outline-secondary'
                aria-label={navExpanded ? "Collapse navigation" : "Expand navigation"}
            >
                {navExpanded ? <MergeIcon /> : <ExtendIcon />}
            </Button>
            <Nav defaultActiveKey="/home" className="flex-column" role="navigation" onSelect={(selectedKey) => navigate(selectedKey)}>
                <Stack gap={3}>
                    {navLinks.map(({ path, label, icon }) => (
                        <Nav.Link 
                            key={path} 
                            eventKey={path} 
                            className="nav-link" 
                            aria-current={path === "/home" ? "page" : undefined}
                            onClick={() => navigate(path)} // Handle navigation
                        >
                            {navExpanded ? icon : null}
                            {navExpanded ? label : icon}
                        </Nav.Link>
                    ))}
                </Stack>
            </Nav>
        </Container>
    );
}

export default Leftbar;
