import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import CheckCircle from '../../images/check-circle.svg';
import CrossCircle from '../../images/times-circle.svg';
import { Modal, Button, Spinner, Toast } from 'react-bootstrap';

const ValidateUsers = () => {
    const [pendingUsers, setPendingUsers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [actionType, setActionType] = useState(null); // 'validate' or 'reject'
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    //defined function twice to use it inside other functions
    const fetchPendingUsers = async () => {
        setLoading(true);
        try {
            const token = sessionStorage.getItem("token");
            const response = await axios.get(`${window.baseURL}/validateUser`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPendingUsers(response.data.users);
        } catch (err) {
            setError(err.response ? err.response.data.message : "Failed to fetch pending users");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchPendingUsers = async () => {
            setLoading(true);
            try {
                const token = sessionStorage.getItem("token");
                const response = await axios.get(`${window.baseURL}/validateUser`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setPendingUsers(response.data.users);
            } catch (err) {
                setError(err.response ? err.response.data.message : "Failed to fetch pending users");
            } finally {
                setLoading(false);
            }
        };

        fetchPendingUsers();
    }, []);

    const handleValidateUser = async (userId) => {
        const token = sessionStorage.getItem("token");
        try {
            await axios.put(`${window.baseURL}/validateUser/${userId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchPendingUsers();
            setPendingUsers(prevUsers => prevUsers.filter(user => user.userId !== userId));
            setToastMessage("User validated successfully!");
            setShowToast(true);
        } catch (err) {
            setError(err.response ? err.response.data.message : "Failed to validate user");
        }
    };

    const handleValidateAllUsers = async () => {
        const token = sessionStorage.getItem("token");
        try {
            await axios.put(`${window.baseURL}/validateAllUsers`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchPendingUsers()
        } catch (err) {
            setError(err.response ? err.response.data.message : "Failed to validate user");
        }
    }

    const handleRejectUser = async (userId) => {
        const token = sessionStorage.getItem("token");
        try {
            await axios.delete(`${window.baseURL}/user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchPendingUsers()
            setPendingUsers(prevUsers => prevUsers.filter(user => user.userId !== userId));
            setToastMessage("User rejected successfully!");
            setShowToast(true);
        } catch (err) {
            setError(err.response ? err.response.data.message : "Failed to reject user");
        }
    };

    const handleRejectAllUsers = async () => {
        const token = sessionStorage.getItem("token");
        try {
            await axios.delete(`${window.baseURL}/rejectAllUsers`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchPendingUsers();
        } catch (err) {
            setError(err.response ? err.response.data.message : "Failed to validate user");
        }
    }

    const handleShowModal = (userId, action) => {
        setCurrentUserId(userId);
        setActionType(action);
        setShowModal(true);
    };

    const handleConfirmAction = async () => {
        if (actionType === 'validate') {
            await handleValidateUser(currentUserId);
        } else if (actionType === 'reject') {
            await handleRejectUser(currentUserId);
        } else if (actionType === 'validateAll'){
            await handleValidateAllUsers();
        } else if (actionType === 'rejectAll'){
            await handleRejectAllUsers();
        }
        setShowModal(false); // Close the modal after action
    };

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className=''>
            <Button className='btn-danger' onClick={() => handleShowModal(null, 'rejectAll')}>Reject all users</Button>
            <Button className='btn-success' onClick={() => handleShowModal(null, 'validateAll')}>Accept all users</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>UID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingUsers.map(user => (
                        <tr key={user.userId}>
                            <td>{user.userId}</td>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>
                                <button
                                    onClick={() => handleShowModal(user.userId, 'validate')}
                                    style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                >
                                    <img
                                        src={CheckCircle}
                                        alt="Accept"
                                        style={{ width: '35px', height: '35px' }}
                                    />
                                </button>
                                <button
                                    onClick={() => handleShowModal(user.userId, 'reject')}
                                    style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                >
                                    <img
                                        src={CrossCircle}
                                        alt="Reject"
                                        style={{ width: '35px', height: '35px' }}
                                    />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>{actionType === 'validate' || actionType === 'validateAll' ? 'Confirm Validation' : 'Confirm Rejection'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {currentUserId
            ? `Are you sure you want to ${actionType === 'validate' ? 'validate' : 'reject'} this user?`
            : `Are you sure you want to ${actionType === 'validateAll' ? 'validate' : 'reject'} all users?`}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmAction}>
        Confirm
        </Button>
    </Modal.Footer>
</Modal>


            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    );
};

export default ValidateUsers;
