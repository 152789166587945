import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import UpdateItems from '../components/Items/UpdateItems';

const ItemsPage = () => {
  useEffect(() => {
    document.title = "Update item";
  }, []);
  return (
   <Layout>
<UpdateItems/>
   </Layout>
  )
}

export default ItemsPage
