import React from 'react';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OpenOrders from './OpenOrders';
import AckOrders from './AckOrders';
import CancelRequestOrders from './CancelRequestOrders';
import ShippedOrders from './ShippedOrders';
import CanceledOrders from './CanceledOrders';
import TerminatedOrders from './TerminatedOrders';
import CompleteOrders from './CompleteOrders';

const Order = () => {
  const [key, setKey] = useState('open');


  return (
    <Container fluid>
      <div className='d-flex justify-content-center p-2'>
        <h1>Orders</h1>
      </div>
      <Tabs
        activeKey={key}
        id='uploadApiTabs'
        onSelect={(k) => setKey(k)}
        className='mb-3'
        justify
        style={{ position: 'sticky', top: '80px', backgroundColor: '#FFFFFF' }}
      >
        <Tab eventKey="open" title='Open Orders' className="bg-light">
          <OpenOrders />
        </Tab>
        <Tab eventKey="acknowledged" title='Acknowledged' className="bg-light">
          <AckOrders />
        </Tab>
        <Tab eventKey="cancelRequest" title='Cancel Request' className="bg-light">
          <CancelRequestOrders />
        </Tab>
        <Tab eventKey="shipped" title='Shipped' className="bg-light">
          <ShippedOrders />
        </Tab>
        <Tab eventKey="canceled" title='Canceled' className="bg-light">
          <CanceledOrders />
        </Tab>
        <Tab eventKey="terminated" title='Terminated' className="bg-light">
          <TerminatedOrders />
        </Tab>
        <Tab eventKey="complete" title='Complete' className="bg-light">
          <CompleteOrders />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Order;
