import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import AddUser from '../components/Users/AddUser';
const AddUserPage = () => {
  useEffect(() => {
    document.title = "Add a user";
  }, []);
  return (
   <Layout>
    <AddUser/>
   </Layout>
  )
}

export default AddUserPage
