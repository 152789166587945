import React, { useState } from 'react';
import Header from './Header';
import Leftbar from './Leftbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Layout = ({ children }) => {
    const [navExpanded, setNavExpanded] = useState(true);
    
    return (
        <Container fluid>
            <Header />
            <Container style={{ paddingTop: '85px', paddingBottom: '80px' }} fluid>
                <Row style={{ height: 'calc(100vh - 85px)' }}> {/* Adjust height here */}
                    <Col md={navExpanded ? 2 : 1}>
                        <Leftbar navExpanded={navExpanded} setNavExpanded={setNavExpanded} />
                    </Col>
                    <Col md={navExpanded ? 10 : 11}>
                        {children}
                    </Col>
                </Row>
            </Container>
            {/* <Footer/> */}
        </Container>
    );
}

export default Layout;
