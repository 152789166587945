import React, {useEffect} from 'react'
import RegisterForm from '../components/Register/ RegisterForm.jsx'

const RegisterPage = () => {
  useEffect(() => {
    document.title = "Register";
  }, []);
  return (
      <div className='d-flex justify-content-center align-items-center'>
        <RegisterForm/>
      </div>
  )
}

export default RegisterPage