import React, { useState, useEffect } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const ChangesHistory = () => {
    const [productitemHistory, setHistory] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const token = sessionStorage.getItem("token");
                const response = await axios.get(`${window.baseURL}/history`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("API Response:", response.data);
                setHistory(response.data.history);
            } catch (err) {
                console.error("Error fetching history:", err);
                setError(err.response ? err.response.data.message : "Failed to fetch productitem_history");
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, []);

    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border" />
                <p>Loading history...</p>
            </div>
        );
    }

    const handleDelete = async (itemsId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (!confirmDelete) return;

        try {
            const token = sessionStorage.getItem("token");
            await axios.delete(`${window.baseURL}/history/${itemsId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert("Item deleted successfully!");
            // Refresh the history after deletion
            setHistory(prevHistory => prevHistory.filter(item => item.itemsId !== itemsId));
        } catch (err) {
            console.error("Error deleting history item:", err);
            setError(err.response ? err.response.data.message : "Failed to delete item history");
        }
    };

    const handleDeleteAll = async () => {
        const confirmDeleteAll = window.confirm("Are you sure you want to delete all history items?");
        if (!confirmDeleteAll) return;

        try {
            const token = sessionStorage.getItem("token");
            await axios.delete(`${window.baseURL}/history`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert("All items deleted successfully!");
            setHistory([]); // Clear the history from state
        } catch (err) {
            console.error("Error deleting all history items:", err);
            setError(err.response ? err.response.data.message : "Failed to delete all item history");
        }
    };

    return (
        <div>
            <div className='d-flex justify-content-center p-2'>
          <h1>History</h1>
        </div>
            {error && (
                <Alert variant="danger">
                    Error: {error}
                </Alert>
            )}
            <Button variant="danger" onClick={handleDeleteAll} className="mb-3">
                Delete All History
            </Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Items ID</th>
                        <th>SKU</th>
                        <th>Barcode</th>
                        <th>Before</th>
                        <th>After</th>
                        <th>Modified at</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {productitemHistory.map(historyItem => (
                        <tr key={historyItem.itemsId}>
                            <td>{historyItem.itemsId}</td>
                            <td>{historyItem.sku}</td>
                            <td>{historyItem.barcode}</td>
                            <td>
                                <div>
                                    <strong>SKU:</strong> {historyItem.sku}<br />
                                    <strong>Barcode:</strong> {historyItem.barcode}<br />
                                    <strong>Size:</strong> {historyItem.beforeSize || 'N/A'}<br />
                                    <strong>Variation Value:</strong> {historyItem.beforeVariationValue || 'N/A'}<br />
                                    <strong>Weight:</strong> {historyItem.beforeWeight || 'N/A'}<br />
                                    <strong>Weight Unit:</strong> {historyItem.beforeWeightUnit || 'N/A'}<br />
                                    <strong>Dimension:</strong> {historyItem.beforeDimension || 'N/A'}<br />
                                    <strong>Currency:</strong> {historyItem.beforeCurrency || 'N/A'}<br />
                                    <strong>Price MSRP:</strong> {historyItem.beforePriceMsrp || 'N/A'}<br />
                                    <strong>Price Cost:</strong> {historyItem.beforePriceCost || 'N/A'}<br />
                                    <strong>Quantity:</strong> {historyItem.beforeQty || 'N/A'}<br />
                                    <strong>Tax Rate:</strong> {historyItem.beforeTaxRate || 'N/A'}<br />
                                    <strong>SKU Active:</strong> {historyItem.beforeSkuActive ? 'Yes' : 'No'}<br />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <strong>SKU:</strong> {historyItem.afterSku || 'N/A'}<br />
                                    <strong>Barcode:</strong> {historyItem.afterBarcode || 'N/A'}<br />
                                    <strong>Size:</strong> {historyItem.afterSize || 'N/A'}<br />
                                    <strong>Variation Value:</strong> {historyItem.afterVariationValue || 'N/A'}<br />
                                    <strong>Weight:</strong> {historyItem.afterWeight || 'N/A'}<br />
                                    <strong>Weight Unit:</strong> {historyItem.afterWeightUnit || 'N/A'}<br />
                                    <strong>Dimension:</strong> {historyItem.afterDimension || 'N/A'}<br />
                                    <strong>Currency:</strong> {historyItem.afterCurrency || 'N/A'}<br />
                                    <strong>Price MSRP:</strong> {historyItem.afterPriceMsrp || 'N/A'}<br />
                                    <strong>Price Cost:</strong> {historyItem.afterPriceCost || 'N/A'}<br />
                                    <strong>Quantity:</strong> {historyItem.afterQty || 'N/A'}<br />
                                    <strong>Tax Rate:</strong> {historyItem.afterTaxRate || 'N/A'}<br />
                                    <strong>SKU Active:</strong> {historyItem.afterSkuActive ? 'Yes' : 'No'}<br />
                                </div>
                            </td>
                            <td>
                                {historyItem.created_at}
                            </td>
                            <td>
                                <Button variant="danger" onClick={() => handleDelete(historyItem.itemsId)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ChangesHistory;
