import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Logo from '../../images/logo.png';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import SearchProduct from './SearchProduct';
import apiFinder from '../../apis/apiFinder';

const ViewProducts = () => {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
        };
        const response = await apiFinder.get("/productsItems", config);
        if (response.data.success) {
          setProducts(response.data.productsWithItems);
        } else {
          setError("Failed to fetch products.");
        }
      } catch (error) {
        setError("Get products failed, try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    const interval = setInterval(fetchProducts, 3600000); // Refresh every hour
    return () => clearInterval(interval);
  }, []);

  const selectImage = (product) => {
    const productImages = product.productImages ? product.productImages.split(';') : [];
    const packageImages = product.packageImages ? product.packageImages.split(';') : [];
    if (packageImages.length > 0) {
      return packageImages[0];
    } else if (productImages.length > 0) {
      return productImages[0];
    } else {
      return Logo; // Default image
    }
  };

  const handleViewMore = (product) => () => {
    navigate('/viewmoreproduct', { state: { product } });
  };

  return (
    <>
      <div className='d-flex justify-content-center p-2'>
        <h1>Products</h1>
      </div>
      <SearchProduct products={products} />
      <Container className='d-flex flex-wrap justify-content-center' fluid>
        <div className='d-flex justify-content-center p-2'>
        </div>
        {loading ? (
          <div className='mt-5'>
            <p>Loading products...</p>
          </div>
        ) : error ? (
          <div className='mt-5'>
            <p className="text-danger">{error}</p>
          </div>
        ) : products ? (
          products.map((product) => (
            <div className='col-sm-4 mb-3 d-flex' key={product.productId}>
              <Card className='text-center mx-4 my-3 flex-fill'>
                <Card.Img className='w-auto h-auto' variant="top" src={selectImage(product)} />
                <Card.Body className='d-flex flex-column'>
                  <Card.Title>Code: {product.code}</Card.Title>
                  <Card.Text className='flex-grow-1'>Title: {product.title}<br />
                    Brand: {product.brand}
                  </Card.Text>
                  <Button variant='outline-success' onClick={handleViewMore(product)}>View</Button>
                  {/* Add the clickable link to the TOF product page */}
                  {product.numId && (
                    <a
                      href={`https://detail.tmall.hk/hk/item.htm?id=${product.numId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-2 btn btn-link"
                    >
                      View on TOF
                    </a>
                  )}
                </Card.Body>
              </Card>
            </div>
          ))
        ) : (
          <p>No products available.</p>
        )}
      </Container>
    </>
  );
}

export default ViewProducts;
