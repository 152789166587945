import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const EditUserProfile = () => {
    const [user, setUser] = useState({
        username: '',
        email: '',
        newPassword: '',
        confirmPassword: '',
        profilePicture: null,
    });

    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get(`${window.baseURL}/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("User data response:", response.data); // Log the response

                const userData = response.data.user;
                const profilePictureUrl = userData.Images ? `http://localhost:3301/uploads/${userData.Images}` : null;

                setUser({
                    username: userData.username,
                    email: userData.email,
                    profilePicture: profilePictureUrl,
                });
            } catch (err) {
                setError(err.response ? err.response.data.message : 'Failed to fetch user data');
            }
        };

        fetchUserData();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setUser((prevUser) => ({ ...prevUser, profilePicture: imageUrl }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user.newPassword !== user.confirmPassword) {
            setError("Passwords should be the identical");
            return;
        }

        setLoading(true);
        try {
            const token = sessionStorage.getItem('token');
            const formData = new FormData();
            formData.append('username', user.username);
            formData.append('email', user.email);
            if (user.newPassword) {
                formData.append('newPassword', user.newPassword);
            }
            if (selectedImage) {
                formData.append('profilePicture', selectedImage);
            }

            const response = await axios.put(`${window.baseURL}/user`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("Profile update response:", response.data);
            setSuccessMessage('Profile updated successfully!');
            setError(null);
            setIsEditing(false);
        } catch (err) {
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                navigate('/login', { replace: true });
            } else {
                setError(err.response ? err.response.data.message : 'Failed to update profile');
                setSuccessMessage(null);
            }
        } finally {
            setLoading(false);
        }
    };

    //debug
    //console.log('profile picture:',user.profilePicture);

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-3">
                    <div className="list-group">
                        <Link to="/UserProfile" className="list-group-item list-group-item-action">Profile</Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <h2>Edit Profile</h2>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    <div className="card">
                        <div className="card-body">
                            {isEditing ? (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="username">Username:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            value={user.username}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={user.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newPassword">New Password:</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="newPassword"
                                            name="newPassword"
                                            value={user.newPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password:</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={user.confirmPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="profilePicture">Profile Picture:</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profilePicture"
                                            name="profilePicture"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    {selectedImage && (
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            alt="Profile Preview"
                                            className="img-fluid rounded-circle mb-3"
                                            style={{ width: '150px', height: '150px' }}
                                        />
                                    )}
                                    <button type="submit" className="btn-success mt-3" disabled={loading}>
                                        {loading ? 'Saving...' : 'Save Changes'}
                                    </button>
                                </form>
                            ) : (
                                <div>
                                    <img
                                        src={user.profilePicture ? user.profilePicture : 'http://localhost:3301/uploads/default.png'}
                                        alt="Profile"
                                        className="img-fluid rounded-circle mb-3"
                                        style={{ width: '150px', height: '150px' }}
                                    />
                                    <h3 className="font-weight-bold">Username: <span>{user.username}</span></h3>
                                    <h3 className="font-weight-bold">Email: <span>{user.email}</span></h3>

                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUserProfile;
