import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import apiFinder from '../../apis/apiFinder';

const UpdateItems = () => {
  const [editItem, setEditItem] = useState(null);
  const [originItem, setOriginItem] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [searchItem, setSearchItem] = useState({
    searchSku: '',
    searchBarcode: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearchItem = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
      };
      const response = await axios.post(`${window.baseURL}/getAItem`, searchItem, config);
      if (response.status === 200 && response.data.success) {
        setEditItem(response.data.itemSearched[0]);
        setOriginItem(JSON.parse(JSON.stringify(response.data.itemSearched[0])));
      } else {
        alert(response.data.message);
        setEditItem(null);
        setOriginItem(null);
      }
    } catch (error) {
      console.error(error);
      setError('Error fetching item. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchItem({
      ...searchItem,
      [name]: value,
    });
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setEditItem(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditItem = () => {
    setIsEditable(true);
  };

  const handleCancelEditItem = () => {
    setEditItem(originItem);
    setIsEditable(false);
  };

  useEffect(() => {
    if (!isEditable) {
      setEditItem(originItem);
    }
  }, [isEditable, originItem]);

  const handleSaveUpdateItem = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
      };
      const response = await apiFinder.post('/updateAItem', editItem, config);
      if (response.status === 200 && response.data.success) {
        console.log('Updated Successfully');
        setIsEditable(false);
        setOriginItem(editItem); // Update originItem to the new values
      } else {
        setError('Failed to update item. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setError('Error updating item. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className='border border-primary' style={{ marginTop: '10px', maxWidth: '100%' }} fluid>
      <div className='d-flex justify-content-center p-2'>
          <h1>Update an item</h1>
        </div>
      <Form className='border border-danger' style={{ marginTop: '10px', maxWidth: '100%' }}>
        <Row className='mb-3'>
          <Form.Group as={Col} controlId='searchSku'>
            <Form.Control type='text' placeholder="Input item's SKU" name='searchSku' onChange={handleSearchChange} />
          </Form.Group>
          <Form.Group as={Col} controlId='searchBarcode'>
            <Form.Control type='text' placeholder="Input item's barcode" name='searchBarcode' onChange={handleSearchChange} />
          </Form.Group>
          <Col>
            <Button type='submit' onClick={handleSearchItem} disabled={loading}>
              {loading ? 'Searching...' : 'Search'}
            </Button>
          </Col>
        </Row>
      </Form>
      {error && <div className="alert alert-danger">{error}</div>}
      {editItem && (
        <Form className='border border-warning' style={{ marginTop: '10px', maxWidth: '100%' }}>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='productCode'>
              <Form.Label>Code</Form.Label>
              <Form.Control type='text' value={editItem.productCode} readOnly name='productCode' />
            </Form.Group>
            <Form.Group as={Col} controlId='sku'>
              <Form.Label>SKU</Form.Label>
              <Form.Control type='text' value={editItem.sku} readOnly name='sku' />
            </Form.Group>
            <Form.Group as={Col} controlId='barcode'>
              <Form.Label>Barcode</Form.Label>
              <Form.Control type='text' value={editItem.barcode} readOnly name='barcode' />
            </Form.Group>
            <Form.Group as={Col} controlId='mfBarcode'>
              <Form.Label>MF Barcode</Form.Label>
              <Form.Control type='text' value={editItem.mfBarcode} readOnly={!isEditable} name='mfBarcode' onChange={handleUpdateChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='size'>
              <Form.Label>Size</Form.Label>
              <Form.Control type='text' value={editItem.size} readOnly={!isEditable} name='size' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='variationValue'>
              <Form.Label>Variation Value</Form.Label>
              <Form.Control type='text' value={editItem.variationValue} readOnly={!isEditable} name='variationValue' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='weight'>
              <Form.Label>Weight</Form.Label>
              <Form.Control type='number' value={editItem.weight} readOnly={!isEditable} name='weight' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='weightUnit'>
              <Form.Label>Weight Unit</Form.Label>
              <Form.Control type='text' value={editItem.weightUnit} readOnly={!isEditable} name='weightUnit' onChange={handleUpdateChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='dimension'>
              <Form.Label>Dimension</Form.Label>
              <Form.Control type='text' value={editItem.dimension} readOnly={!isEditable} name='dimension' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='currency'>
              <Form.Label>Currency</Form.Label>
              <Form.Control type='text' value={editItem.currency} readOnly={!isEditable} name='currency' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='priceMsrp'>
              <Form.Label>Price MSRP</Form.Label>
              <Form.Control type='number' value={editItem.priceMsrp} readOnly={!isEditable} name='priceMsrp' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='priceCost'>
              <Form.Label>Price Cost</Form.Label>
              <Form.Control type='number' value={editItem.priceCost} readOnly={!isEditable} name='priceCost' onChange={handleUpdateChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='qty'>
              <Form.Label>Quantity</Form.Label>
              <Form.Control type='number' value={editItem.qty} readOnly={!isEditable} name='qty' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='taxRate'>
              <Form.Label>Tax Rate</Form.Label>
              <Form.Control type='number' value={editItem.taxRate} readOnly={!isEditable} name='taxRate' onChange={handleUpdateChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='skuActive'>
              <Form.Label>SKU Active</Form.Label>
              <Form.Control type='text' value={editItem.skuActive} readOnly={!isEditable} name='skuActive' onChange={handleUpdateChange} />
            </Form.Group>
          </Row>
          {!isEditable && <Button variant='outline-primary' style={{ width: '100%', position: 'sticky', bottom: '60px' }} onClick={handleEditItem}>Edit Item</Button>}
          {isEditable && <Button variant='outline-primary' style={{ width: '100%', position: 'sticky', bottom: '60px' }} onClick={handleSaveUpdateItem}>Save</Button>}
          {isEditable && <Button variant='outline-primary' style={{ width: '100%', position: 'sticky', bottom: '60px' }} onClick={handleCancelEditItem}>Cancel</Button>}
        </Form>
      )}
    </Container>
  );
};

export default UpdateItems;
