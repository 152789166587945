import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import UploadAPI from '../components/API/UploadAPI';

const UploadAPIPage = () => {
  useEffect(() => {
    document.title = "Upload API";
  }, []);
  return (
    <Layout>
<UploadAPI/>
    </Layout>
  )
}

export default UploadAPIPage
