import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const UploadPriceApi = ({apiProducts,getApiProducts}) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedItemsPrice,setSelectedItemsPrice]=useState([]);
    const skuMap = new Map();
    if (getApiProducts) {
        getApiProducts.forEach(product => {
            product.items.forEach(item => {
                const extendedItem = {
                    ...item,
                    productCode: product.code
                };
                skuMap.set(item.sku, extendedItem);  // 直接存储单个 item 而不是数组
            });
        });
    }
    // 处理复选框更改
    const handleCheckboxChange = (identifier, isChecked) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [identifier]: isChecked
        }));
    };
     // 获取所有选中的项
     const getSelectedItems = () => {
        const selectedItems = apiProducts.flatMap(product =>
            product.items.filter(item => checkedItems[`${item.productCode}-${item.barcode}`])
        );
        return selectedItems.map(item=>({
            code:item.productCode,
            sku:item.sku,
            currency:item.currency,
            priceMsrp:item.priceMsrp,
            priceCost:item.priceCost,
            priceRetail:item.priceMsrp,
            saleCurrency:item.currency,
            saleMsrp: item.priceMsrp,
            saleCost: item.priceCost,
            saleRetail: item.priceMsrp,
        }));

        // console.log(uploadData);
        //setSelectedItemsPrice(uploadData);
    };

const handleUploadPrice =async() => {
    try{
       const select= getSelectedItems();
        console.log(select);
        const config={
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            },
          }
          const response = await axios.post('http://localhost:3301/api/uploadPrice', select,config);
          if (response.status === 200&&response.data.success) {
            // 处理成功保存数据的情况
            console.log(response.data.results);
            console.log('Product upload price successfully');
  
          } else {
            // 处理无法保存数据的情况
            console.log(response.data.message);
            console.error('Failed to update');
          }
    }catch(error){
        console.error('Error upload price :', error); 
    }
}
function formatTimeDifference(updatedAtString) {
    const updatedAt = new Date(updatedAtString);
    const now = new Date();
    const diff = now - updatedAt; // 毫秒差

    const diffInMinutes = Math.floor(diff / (1000 * 60)); // 转换为分钟
    const minutes = diffInMinutes % 60;
    const diffInHours = Math.floor(diffInMinutes / 60);
    const hours = diffInHours % 24;
    const days = Math.floor(diffInHours / 24);

    return `${days}Days${hours}Hours${minutes}Minutes Before`;
}
  return (
    <Container fluid>
        <Table hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Sku</th>
              <th>Barcode</th>
              <th>currency</th>
              <th>priceMsrp</th>
              <th>priceCost</th>
              <th>UpdatedTimeInLocal</th>
            </tr>
          </thead>
          <tbody > 
    {apiProducts ? (
    apiProducts.map((product, productIndex) => (
      <React.Fragment key={`fragment-${product.code}-${productIndex}`}>
             {/* 针对每个产品使用产品代码和索引生成唯一key */}
         <tr key={`priceProduct-${product.code}-${productIndex}`}>
         <td>{productIndex + 1}</td>
         <td>{product.code}</td>
        </tr>
        {product.items && product.items.length > 0 ? (
          product.items.map((item, itemIndex) => {
            const matchedProduct = skuMap.get(item.sku) || []; 
           return (
            <React.Fragment key={`item-fragment-${item.itemsId}`}>
             {/* 针对每个项目使用产品代码、SKU、项目索引和产品索引生成唯一key */}
         <tr key={`priceItem-${item.itemsId}`}>
            <td ><Form.Check  type='checkbox'  id={`check-${item.barcode}-${item.itemsId}`} checked={!!checkedItems[`${item.sku}-${item.barcode}`]}
             onChange={e => handleCheckboxChange(`${item.sku}-${item.barcode}`, e.target.checked)} /></td>
              <td >{item.productCode}</td>
              <td>{item.sku}</td>
              <td>{item.barcode}</td>
              <td>{item.currency}</td>
              <td>{item.priceMsrp}</td>
              <td>{item.priceCost}</td>
              <td>{formatTimeDifference(item.updatedAt)}</td>
            </tr>
            <tr key={`apiProductPriceItem-${matchedProduct.sku}-${matchedProduct.barcode}`} className="table table-warning">
                <td>API</td>
                <td>{matchedProduct.productCode}</td>
                <td>{matchedProduct.sku}</td>
                <td>{matchedProduct.barcode}</td>
                <td>{matchedProduct.currency}</td>
                <td>{matchedProduct.priceMsrp}</td>
                <td>{matchedProduct.priceCost}</td>
            </tr>
            </React.Fragment>
          )
          })
        ) : (
          <tr key={`productPriceItemLoading-${product.productId}-${productIndex}`} >
            <td colSpan="3">Item loading...</td>
          </tr>
        )}
        <tr>

        </tr>

      </React.Fragment>
      
    ))
  ) : (
    <tr key={`productPriceProductLoading`}>
      <td colSpan="3">Products loading...</td>
    </tr>
  )}
 </tbody>
  </Table>
  <Button variant='success' className='text-center' style={{width: '100%',position:'sticky',bottom:'60px'}} onClick={handleUploadPrice } >Selected Upload </Button> 
    </Container>
  )
}

export default UploadPriceApi
