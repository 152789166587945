import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import AddProducts from '../components/Products/AddProducts';
const AddProductsPage = () => {
  useEffect(() => {
    document.title = "Add a product";
  }, []);
  return (
   <Layout>
    <AddProducts/>
   </Layout>
  )
}

export default AddProductsPage
