import axios from "axios";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/v1/ev2ltrade"
    : "http://localhost:3301/api/v1/ev2ltrade";

export default axios.create({
    baseURL:baseURL,
    // baseURL:"http://localhost:3301/api/v1/ev2ltrade",
});
