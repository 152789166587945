import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/esm/Button';
import apiFinder from '../../apis/apiFinder';

const OpenOrders = () => {
  const [openOrders, setOpenOrders] = useState([]);
  const [openUserOrders, setOpenUserOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const userResponse = await axios.get(`${window.baseURL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(userResponse.data.user);

        // Check if the user is an admin
        if (userResponse.data.user && userResponse.data.user.role === 'admin') {
          const ordersResponse = await apiFinder.post("/api/getOrdersByStatus", { status: "Open" }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });

          if (ordersResponse.data.success) {
            setOpenOrders(ordersResponse.data.orders);
            console.log('Open orders:', ordersResponse.data.orders); // Log orders
          }
        }else {
          const userOrdersResponse = await axios.get(`${window.baseURL}/openorders`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setOpenUserOrders(userOrdersResponse.data.orders);
          console.log('fetched user orders');
        }


      } catch (err) {
        console.error(err);
        setError(err.response ? err.response.data.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUserAckOrderItem = async (orderId, productId) => {
    try {
      const response = await axios.post("http://localhost:3301/api/v1/ev2ltrade/ackopenorderitem", { orderId, productId }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.success) {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log("Failed to acknowledge user order item");
    }
  }

  const handleAckOrder = async (orderId) => {
    try {
      const response = await apiFinder.post("/api/ackOrder", { orderId }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.success) {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log("Failed to acknowledge order");
    }
  };

  if (loading) {
    return <Container fluid><p>Loading...</p></Container>;
  }

  if (error) {
    return <Container fluid><p>{error}</p></Container>;
  }

  const ordersToDisplay = userData && userData.role === 'admin' ? openOrders : openUserOrders;
  debugger
  console.log("openOrders",openOrders);
  console.log("openUserOrders",openUserOrders);
  console.log("ordersToDisplay",ordersToDisplay);
  return (
    <Container fluid>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Order:</th>
            <th>OrderId</th>
            <th>OrderTime</th>
            <th>platformId</th>
            <th>cartId</th>
            <th>cartOrderId</th>
            <th>orderStatus</th>
            <th>fromWarehouseKey</th>
            <th>platformCurrency</th>
            <th>platformAmount</th>
            <th>platformDiscount</th>
            <th>platformTax</th>
            <th>platformShippingFee</th>
            <th>exchangeRate</th>
            <th>currency</th>
            <th>amount</th>
            <th>discount</th>
            <th>tax</th>
            <th>shippingFee</th>
          </tr>
        </thead>
        <tbody>
          {ordersToDisplay.length > 0 ? ordersToDisplay.map((order, orderIndex) => (
            <React.Fragment key={order.orderId}>
              <tr>
                <td>{orderIndex + 1}</td>
                <td>{order.orderId}</td>
                <td>{order.orderTime}</td>
                <td>{order.platformId}</td>
                <td>{order.cartId}</td>
                <td>{order.cartOrderId}</td>
                <td>{order.orderStatus}</td>
                <td>{order.fromWarehouseKey}</td>
                <td>{order.platformCurrency}</td>
                <td>{order.platformAmount}</td>
                <td>{order.platformDiscount}</td>
                <td>{order.platformTax}</td>
                <td>{order.platformShippingFee}</td>
                <td>{order.exchangeRate}</td>
                <td>{order.currency}</td>
                <td>{order.discount}</td>
                <td>{order.tax}</td>
              </tr>
              <tr>
                <th>Items:</th>
                <th>SKU</th>
                <th>Model</th>
                <th>Title</th>
                <th>Qty</th>
                <th>Platform Unit Price</th>
                <th>Platform Amount</th>
                <th>Platform Tax</th>
                <th>Platform Discount</th>
                <th>Platform Shipping Fee</th>
                <th>Cost</th>
                <th>Unit Price</th>
                <th>Amount</th>
                <th>Tax</th>
                <th>Discount</th>
                <th>Shipping Fee</th>
              </tr>
              {order.items && order.items.length > 0 ? order.items.map((item, itemIndex) => (
                <tr key={item.productId}>
                  <td></td>
                  <td>{item.sku}</td>
                  <td>{item.model}</td>
                  <td>{item.title}</td>
                  <td>{item.qty}</td>
                  <td>{item.platformUnitPrice}</td>
                  <td>{item.platformAmount}</td>
                  <td>{item.platformTax}</td>
                  <td>{item.platformDiscount}</td>
                  <td>{item.platformShippingFee}</td>
                  <td>{item.cost}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.amount}</td>
                  <td>{item.tax}</td>
                  <td>{item.discount}</td>
                  <td>{item.shippingFee}</td>
                </tr>
              )) : (
                <tr><td colSpan="16">No items found for this order.</td></tr>
              )}
              <tr>
                <td colSpan="3">
                  {userData && userData.role === 'admin' ? (
                    <Button onClick={() => handleAckOrder(order.orderId)}>Acknowledge order: {order.orderId}</Button>
                  ) : (
                    order.items && order.items.length > 0 && order.items.map((item) => (
                      <Button key={item.productId} onClick={() => handleUserAckOrderItem(order.orderId, item.productId)}>Acknowledge item: {item.productId}</Button>
                    ))
                  )}
                </td>
              </tr>
            </React.Fragment>
          )) : (
            <tr>
              <td colSpan="18">No orders found.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default OpenOrders;
