
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/custom.scss';
import {  Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import EditProductsPage from './pages/EditProductsPage';
import AddProductsPage from './pages/AddProductsPage';
import OrdersPage from './pages/OrdersPage';
import ViewProductPage from './pages/ViewProductPage';
import ItemsPage from './pages/ItemsPage';
import ItemPricePage from './pages/ItemPricePage';
import ItemQuantityPage from './pages/ItemQuantityPage';
import PrivateRoute from './Hooks/PrivateRoute';
import UploadAPIPage from './pages/UploadAPIPage';
import ManageUsersPage from './pages/ManageUsersPage';
import ChangesHistoryPage from './pages/ChangesHistoryPage';
import AddUserPage from './pages/AddUserPage';
import EditUserProfilePage from './pages/EditUserProfilePage';
import MessagesPage from './pages/MessagesPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

const App=()=>(
    <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/reset-password/:token" element={<ResetPasswordPage/>} />
    <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
    <Route path="/editproducts" element={<PrivateRoute><EditProductsPage /></PrivateRoute>} />
    <Route path="/addProducts" element={<PrivateRoute><AddProductsPage/></PrivateRoute>} />
    <Route path="/orders" element={<PrivateRoute><OrdersPage/></PrivateRoute>}/>
    <Route path="/viewmoreproduct" element={<PrivateRoute><ViewProductPage/></PrivateRoute>}/>
    <Route path="/updateItems" element={<PrivateRoute><ItemsPage/></PrivateRoute>}/>
    <Route path="/updatePrice" element={<PrivateRoute><ItemPricePage/></PrivateRoute>}/>
    <Route path="/updateQuantity" element={<PrivateRoute><ItemQuantityPage/></PrivateRoute>}/>
    <Route path="/uploadApi" element={<PrivateRoute><UploadAPIPage/></PrivateRoute>}/>
    <Route path="/manageusers" element={<PrivateRoute><ManageUsersPage/></PrivateRoute>} />
    <Route path="/changesHistory" element={<PrivateRoute><ChangesHistoryPage/></PrivateRoute>} />
    <Route path="/addUser" element={<PrivateRoute><AddUserPage/></PrivateRoute>} />
    <Route path="/UserProfile" element={<PrivateRoute><EditUserProfilePage/></PrivateRoute>} />
    <Route path="/Messages" element={<PrivateRoute><MessagesPage/></PrivateRoute>} />
    
    </Routes>
);
export default App;
