// AuthProvider.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import apiFinder from '../apis/apiFinder';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("loginUser")));
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const login = useCallback(async (userlogin) => {
    setLoading(true); // Set loading to true
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        }
      };
      const response = await apiFinder.post('/login', userlogin, config);
      if (response.data.success) {
        const { username, email, role, userId, token } = response.data.userInfo; // Assuming userId is returned
        const userData = { username, email, role, userId }; // Include userId
        setUser(userData);
        sessionStorage.setItem("loginUser", JSON.stringify(userData));
        sessionStorage.setItem("token", token);
        navigate('/home');
        console.log("Login successfully");
      } else {
        throw new Error("Login failed, try again"); // Throw an error if login fails
      }
    } catch (error) {
      console.error("Login Failed", error);
      throw new Error(error.response?.data?.message || 'An error occurred during login.'); // Throw a more descriptive error
    } finally {
      setLoading(false); // Set loading to false
    }
  }, [navigate]);

  const logout = useCallback(() => {
    sessionStorage.removeItem("loginUser");
    sessionStorage.removeItem("token");
    setUser(null);
    navigate('/login');
    console.log("Logout successfully");
  }, [navigate]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
