import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import apiFinder from '../../apis/apiFinder';

const ShippedOrders = () => {
  const [shippedUserOrders, setShippedUserOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');

        // Fetch user data
        const userResponse = await axios.get(`${window.baseURL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(userResponse.data.user);

        if (userResponse.data.user && userResponse.data.user.role === 'admin') {
        const ordersResponse = await apiFinder.post("/api/getOrdersByStatus", { status: "Shipped" }, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        });
        if (ordersResponse.data.success) {
          setShippedOrders(ordersResponse.data.orders);
        }
      }

        // Fetch user shipped orders
        const userOrdersResponse = await axios.get(`${window.baseURL}/shippedorders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setShippedUserOrders(userOrdersResponse.data.orders);
        console.log('fetched user shipped orders');
      } catch (err) {
        console.error(err);
        setError(err.response ? err.response.data.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Container fluid><p>Loading...</p></Container>;
  }

  if (error) {
    return <Container fluid><p>{error}</p></Container>;
  }

  const renderOrderRows = (orders) => {
    return orders.map((order, orderIndex) => {
      const { orderId, orderTime, platformId, cartId, cartOrderId, orderStatus, fromWarehouseKey, platformCurrency, platformAmount, platformDiscount, platformTax, platformShippingFee, exchangeRate, currency, amount, discount, tax, shippingFee, items } = order;

      return (
        <React.Fragment key={`shippedOrder-${orderIndex}`}>
          <tr>
            <td>{orderIndex + 1}</td>
            <td>{orderId}</td>
            <td>{orderTime}</td>
            <td>{platformId}</td>
            <td>{cartId}</td>
            <td>{cartOrderId}</td>
            <td>{orderStatus}</td>
            <td>{fromWarehouseKey}</td>
            <td>{platformCurrency}</td>
            <td>{platformAmount}</td>
            <td>{platformDiscount}</td>
            <td>{platformTax}</td>
            <td>{platformShippingFee}</td>
            <td>{exchangeRate}</td>
            <td>{currency}</td>
            <td>{amount}</td>
            <td>{discount}</td>
            <td>{tax}</td>
            <td>{shippingFee}</td>
          </tr>
          <tr>
            <th>Items:</th>
            <th>sku</th>
            <th>code</th>
            <th>model</th>
            <th>title</th>
            <th>qty</th>
            <th>platformUnitPrice</th>
            <th>platformAmount</th>
            <th>platformTax</th>
            <th>platformDiscount</th>
            <th>platformShippingFee</th>
            <th>cost</th>
            <th>unitPrice</th>
            <th>amount</th>
            <th>tax</th>
            <th>discount</th>
            <th>shippingFee</th>
          </tr>
          {items ? items.map((item, itemIndex) => (
            <tr key={`shippedOrderItem-${itemIndex}`}>
              <td></td>
              <td>{item.sku}</td>
              <td>{item.code}</td>
              <td>{item.model}</td>
              <td>{item.title}</td>
              <td>{item.qty}</td>
              <td>{item.platformUnitPrice}</td>
              <td>{item.platformAmount}</td>
              <td>{item.platformTax}</td>
              <td>{item.platformDiscount}</td>
              <td>{item.platformShippingFee}</td>
              <td>{item.cost}</td>
              <td>{item.unitPrice}</td>
              <td>{item.amount}</td>
              <td>{item.tax}</td>
              <td>{item.discount}</td>
              <td>{item.shippingFee}</td>
            </tr>
          )) : (
            <tr><td colSpan="3">Items loading...</td></tr>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Container fluid>
      {userData && userData.role === 'admin' ? (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Order:</th>
              <th>OrderId</th>
              <th>OrderTime</th>
              <th>platformId</th>
              <th>cartId</th>
              <th>cartOrderId</th>
              <th>orderStatus</th>
              <th>fromWarehouseKey</th>
              <th>platformCurrency</th>
              <th>platformAmount</th>
              <th>platformDiscount</th>
              <th>platformTax</th>
              <th>platformShippingFee</th>
              <th>exchangeRate</th>
              <th>currency</th>
              <th>amount</th>
              <th>discount</th>
              <th>tax</th>
              <th>shippingFee</th>
            </tr>
          </thead>
          <tbody>
            {shippedOrders.length > 0 ? renderOrderRows(shippedOrders) : (
              <tr>
                <td colSpan="18">Shipped Orders Empty...</td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <Container>
          <Table>
            <thead>
              <tr>
                <th>Order:</th>
                <th>OrderId</th>
                <th>OrderTime</th>
                <th>platformId</th>
                <th>cartId</th>
                <th>cartOrderId</th>
                <th>orderStatus</th>
                <th>fromWarehouseKey</th>
                <th>platformCurrency</th>
                <th>platformAmount</th>
                <th>platformDiscount</th>
                <th>platformTax</th>
                <th>platformShippingFee</th>
                <th>exchangeRate</th>
                <th>currency</th>
                <th>amount</th>
                <th>discount</th>
                <th>tax</th>
                <th>shippingFee</th>
              </tr>
            </thead>
            <tbody>
              {shippedUserOrders.length > 0 ? renderOrderRows(shippedUserOrders) : (
                <tr>
                  <td colSpan="18">No orders found.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      )}
    </Container>
  );
};

export default ShippedOrders;
