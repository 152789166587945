import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import apiFinder from '../../apis/apiFinder';
import { useNavigate } from 'react-router-dom';

const ViewMoreProduct = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const { product } = location.state;
  const items = product.items;
  const [isEditable, setIsEditable] = useState(false);
  const [editedProduct, setEditedProduct] = useState({ ...product });
  const productImages = product.productImages ? product.productImages.split(';') : [];
  const packageImages = product.packageImages ? product.packageImages.split(';') : [];
  const handleEditClick = async () => {
    if (isEditable) {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
        const response = await apiFinder.put('updateProductItems', editedProduct, config);
        if (response.status === 200 && response.data.success) {
          // 处理成功保存数据的情况
          console.log(response.data.message);

        } else {
          // 处理无法保存数据的情况
          console.error('Failed to update');
        }
      } catch (error) {
        // 捕获并处理请求中的错误
        console.error('Error updating product :', error);
      }
    }
    setIsEditable(!isEditable);

  };
  const handleCancelEdit = () => {
    setIsEditable(false);
  };
  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct(prevProduct => ({
      ...prevProduct,
      [name]: value
    }));
  };
  const handleItemChange = (barcode, e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      items: prevProduct.items.map((item) =>
        item.barcode === barcode ? { ...item, [name]: value } : item
      ),
    }));
  };


  return (
    <div>
      <Button className='mb-3 mt-3' onClick={() => Navigate('/editproducts')}>Back</Button>
      <Container className='mw-100'>
        <Form>
          <Row className='mb-3 '>
            <Form.Group as={Col} controlId='code' className='border border-danger' >
              <Form.Label>Code</Form.Label>
              <Form.Control type='text' defaultValue={product.code} name='code' readOnly={!isEditable} onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='mfCode' >
              <Form.Label>MFCode</Form.Label>
              <Form.Control type='text' defaultValue={product.mfCode} readOnly={!isEditable} name='mfCode' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='model' >
              <Form.Label>Model</Form.Label>
              <Form.Control type='text' defaultValue={product.model} readOnly={!isEditable} name='model' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='title' >
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' defaultValue={product.title} readOnly={!isEditable} name='title' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='shortTitle' >
              <Form.Label>ShortTitle</Form.Label>
              <Form.Control type='text' defaultValue={product.shortTitle} readOnly={!isEditable} name='shortTitle' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='brand' >
              <Form.Label>Brand</Form.Label>
              <Form.Control type='text' defaultValue={product.brand} readOnly={!isEditable} name='brand' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='category' >
              <Form.Label>Category</Form.Label>
              <Form.Control type='text' defaultValue={product.category} readOnly={!isEditable} name='cateogry' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='tkofCategoryId' >
              <Form.Label>TKOFCategoryId</Form.Label>
              <Form.Control type='text' defaultValue={product.tkofCategoryId} readOnly={!isEditable} name='tkofCategoryId' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='mfModel' >
              <Form.Label>MFModel</Form.Label>
              <Form.Control type='text' defaultValue={product.mfModel} readOnly={!isEditable} name='mfModel' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='color' >
              <Form.Label>Color</Form.Label>
              <Form.Control type='text' defaultValue={product.color} readOnly={!isEditable} name='color' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='material' >
              <Form.Label>Material</Form.Label>
              <Form.Control type='text' defaultValue={product.material} readOnly={!isEditable} name='material' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='origin' >
              <Form.Label>Origin</Form.Label>
              <Form.Control type='text' defaultValue={product.origin} readOnly={!isEditable} name='origin' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='productType' >
              <Form.Label>ProductType</Form.Label>
              <Form.Control type='text' defaultValue={product.productType} readOnly={!isEditable} name='productType' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='sizeChartType' >
              <Form.Label>SizeChartType</Form.Label>
              <Form.Control type='text' defaultValue={product.sizeChartType} readOnly={!isEditable} name='sizeChartType' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='sizeChartImage' >
              <Form.Label>SizeChartImage</Form.Label>
              <Form.Control type='text' defaultValue={product.sizeChartImage} readOnly={!isEditable} name='sizeChartImage' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='sizeType' >
              <Form.Label>SizeType</Form.Label>
              <Form.Control type='text' defaultValue={product.sizeType} readOnly={!isEditable} name='sizeType' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='productUsage' >
              <Form.Label>Usage</Form.Label>
              <Form.Control type='text' defaultValue={product.productUsage} readOnly={!isEditable} name='productUsage' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='clientProductURL' >
              <Form.Label>ClientProductURL</Form.Label>
              <Form.Control type='text' defaultValue={product.clientProductURL} readOnly={!isEditable} name='clientProductURL' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='industry' >
              <Form.Label>Industry</Form.Label>
              <Form.Control type='text' defaultValue={product.industry} readOnly={!isEditable} name='industry' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='publishSeason' >
              <Form.Label>PublishSeason</Form.Label>
              <Form.Control type='text' defaultValue={product.publishSeason} readOnly={!isEditable} name='publishSeason' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='productImages' >
              <Form.Label>ProductImages</Form.Label>
              <Form.Control as='textarea' rows={3} defaultValue={product.productImages} readOnly={!isEditable} name='productImages' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='packageImages' >
              <Form.Label>PackageImages</Form.Label>
              <Form.Control as='textarea' rows={3} defaultValue={product.packageImages} readOnly={!isEditable} name='packageImages' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='description' >
              <Form.Label>description</Form.Label>
              <Form.Control as='textarea' rows={3} defaultValue={product.description} readOnly={!isEditable} name='description' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='shortDescription' >
              <Form.Label>shortDescription</Form.Label>
              <Form.Control as='textarea' rows={3} defaultValue={product.shortDescription} readOnly={!isEditable} name='shortDescription' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3 border border-danger'>
            <Form.Group as={Col} controlId='AttributeKey1' >
              <Form.Label>AttributeKey1</Form.Label>
              <Form.Control type='text' defaultValue={product.AttributeKey1} readOnly={!isEditable} name='AttributeKey1' onChange={handleProductChange} />
            </Form.Group>
            <Form.Group as={Col} controlId='AttributeValue1' >
              <Form.Label>AttributeValue1</Form.Label>
              <Form.Control type='text' defaultValue={product.AttributeValue1} readOnly={!isEditable} name='AttributeValue1' onChange={handleProductChange} />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} controlId='createdAt' >
              <Form.Label>CreatedTime</Form.Label>
              <Form.Control type='daytime' defaultValue={product.createdAt} readOnly name='createdAt' />
            </Form.Group>
            <Form.Group as={Col} controlId='updatedAt' >
              <Form.Label>UpdatedTime</Form.Label>
              <Form.Control type='daytime' defaultValue={product.updatedAt} readOnly name='updatedAt' />
            </Form.Group>
          </Row>
        </Form>
        {items ? items.map((item, index) => (
          <Form key={item.barcode} >
            <Form.Text >Item:</Form.Text>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId={`sku-${item.barcode}`} className='border border-danger'>
                <Form.Label>SKU</Form.Label>
                <Form.Control type='text' defaultValue={item.sku} readOnly={!isEditable} name='sku' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`barcode-${item.barcode}`} className='border border-danger'>
                <Form.Label>Barcode</Form.Label>
                <Form.Control type='text' defaultValue={item.barcode} readOnly={!isEditable} name='barcode' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`mfBarcdoe-${item.barcode}`} >
                <Form.Label>MFBarcode</Form.Label>
                <Form.Control type='text' defaultValue={item.mfBarcode} readOnly={!isEditable} name='mfBarcode' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId={`size-${item.barcode}`} >
                <Form.Label>Size</Form.Label>
                <Form.Control type='text' defaultValue={item.size} readOnly={!isEditable} name='size' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`variationValue-${item.barcode}`} >
                <Form.Label>variationValue</Form.Label>
                <Form.Control type='text' defaultValue={item.variationValue} readOnly={!isEditable} name='variationValue' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`weight-${item.barcode}`} >
                <Form.Label>Weight</Form.Label>
                <Form.Control type='text' defaultValue={item.weight} readOnly={!isEditable} name='weight' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`weightUnit-${item.barcode}`} >
                <Form.Label>weightUnit</Form.Label>
                <Form.Control type='text' defaultValue={item.weightUnit} readOnly={!isEditable} name='weightUnit' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`dimension-${item.barcode}`} >
                <Form.Label>Dimension</Form.Label>
                <Form.Control type='text' defaultValue={item.dimension} readOnly={!isEditable} name='dimension' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId={`currency-${item.barcode}`} >
                <Form.Label>Currency</Form.Label>
                <Form.Control type='text' defaultValue={item.currency} readOnly={!isEditable} name='currency' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`msrp-${item.barcode}`}>
                <Form.Label>MSRP</Form.Label>
                <Form.Control type='text' defaultValue={item.priceMsrp} readOnly={!isEditable} name='priceMsrp' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`priceCost-${item.barcode}`} >
                <Form.Label>CostPrice</Form.Label>
                <Form.Control type='text' defaultValue={item.priceCost} readOnly={!isEditable} name='priceCost' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`taxRate-${item.barcode}`} >
                <Form.Label>taxRate</Form.Label>
                <Form.Control type='text' defaultValue={item.taxRate} readOnly={!isEditable} name='taxRate' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`qty-${item.barcode}`} >
                <Form.Label>Quantity</Form.Label>
                <Form.Control type='text' defaultValue={item.qty} readOnly={!isEditable} name='qty' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId={`alive-${item.barcode}`} >
                <Form.Label>Alive</Form.Label>
                <Form.Control type='text' defaultValue={item.alive} readOnly={!isEditable} name='alive' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`skuAktive-${item.barcode}`} >
                <Form.Label>SkuActive</Form.Label>
                <Form.Control type='text' defaultValue={item.skuActive} readOnly={!isEditable} name='skuActive' onChange={(e) => handleItemChange(item.barcode, e)} />
              </Form.Group>
              <Form.Group as={Col} controlId={`itemCreatedAt-${item.barcode}`} >
                <Form.Label>ItemCreatedTime</Form.Label>
                <Form.Control type='daytime' defaultValue={item.createdAt} readOnly name='itemCreatedAt' />
              </Form.Group>
              <Form.Group as={Col} controlId={`itemUpdatedAt-${item.barcode}`} >
                <Form.Label>ItemUpdatedTime </Form.Label>
                <Form.Control type='daytime' defaultValue={item.updatedAt} readOnly name='itemUpdatedAt' />
              </Form.Group>
            </Row>
          </Form>
        )) : <p> items empty ...</p>}
        <Button variant='success' className='text-center' style={{ width: isEditable ? '50%' : '100%', position: 'sticky', bottom: '60px' }} onClick={handleEditClick}>
          {isEditable ? 'Save' : 'Edit'}
        </Button>
        {/* {isEditable&&<Button variant='success' className='text-center' style={{width:'100%',position:'sticky',bottom:'200px'}} onClick={ handleCancelEdit}>Add New Item</Button>} */}
        {isEditable && <Button variant='success' className='text-center' style={{ width: '50%', position: 'sticky', bottom: '60px' }} onClick={handleCancelEdit}>Cancel</Button>}
      </Container>
    </div>
  )
}

export default ViewMoreProduct;
