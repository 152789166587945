import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ValidateUsers from './ValidateUsers'; // Import the ValidateUsers component
import edit from '../../images/edit.svg';
import trash from '../../images/trash.svg';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false); // State for confirmation modal
    const [currentUser, setCurrentUser] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null); // State for user to delete
    const [activeTab, setActiveTab] = useState('manage'); // State for active tab
    const currentUserId = JSON.parse(sessionStorage.getItem("loginUser")).userId;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = sessionStorage.getItem("token");
                const response = await axios.get(`${window.baseURL}/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUsers(response.data.users);
            } catch (err) {
                setError(err.response ? err.response.data.message : "Failed to fetch users");
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleAddUser = () => {
        console.log('redirecting...');
        navigate('/addUser');
    };

    const handleShowModal = (user) => {
        setCurrentUser(user);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentUser(null);
    };

    const handleSaveChanges = async () => {
        try {
            const token = sessionStorage.getItem("token");
            await axios.put(`${window.baseURL}/users/${currentUser.userId}`, currentUser, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedUsers = users.map(user => user.userId === currentUser.userId ? currentUser : user);
            setUsers(updatedUsers);
            handleCloseModal();
        } catch (err) {
            setError(err.response ? err.response.data.message : "Failed to update user");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser(prevUser => ({ ...prevUser, [name]: value }));
    };

    const handleDeleteUser = (userId) => {
        if (userId === currentUserId) {
            alert("You cannot delete your own account.");
            return;
        }
        setUserToDelete(userId); // Set the user ID to delete
        setShowConfirmModal(true); // Show confirmation modal
    };

    const confirmDeleteUser = async () => {
        const token = sessionStorage.getItem("token");

        try {
            await axios.delete(`${window.baseURL}/user/${userToDelete}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUsers(prevUsers => prevUsers.filter(user => user.userId !== userToDelete));
            alert("User deleted successfully!");
        } catch (err) {
            console.error("Error deleting user:", err);
            setError(err.response ? err.response.data.message : "Failed to delete user");
        } finally {
            setShowConfirmModal(false); // Close confirmation modal
            setUserToDelete(null); // Reset user to delete
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h1>Manage Users</h1>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
                <Tab eventKey="manage" title="Manage Users">
                    <Button className='mb-4' variant="primary" onClick={handleAddUser}>Add User</Button>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>UID</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.userId}>
                                    <td>{user.userId}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role ? user.role : 'Client'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleShowModal(user)}
                                            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                        >
                                            <img
                                                src={edit}
                                                alt="Edit"
                                                style={{ width: '35px', height: '35px' }}
                                            />
                                        </button>
                                        <button
                                            onClick={() => handleDeleteUser(user.userId)}
                                            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                        >
                                            <img
                                                src={trash}
                                                alt="Delete"
                                                style={{ width: '35px', height: '35px' }}
                                            />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="validations" title="Validations">
                    <ValidateUsers /> {/* Render the ValidateUsers component */}
                </Tab>
            </Tabs>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentUser && (
                        <div>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    value={currentUser.username || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={currentUser.email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="role" className="form-label">Role</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="role"
                                    name="role"
                                    value={currentUser.role || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this user?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteUser}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ManageUsers;
