import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import ManageUsers from '../components/Users/ManageUsers';

const ManageUsersPage = () => {
    useEffect(() => {
        document.title = "Manage users";
      }, []);
    return(
        <Layout><ManageUsers/></Layout>
    )
}

export default ManageUsersPage