import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from '../../images/logo.png';
import { useAuth } from '../../Hooks/AuthContext';
import axios from 'axios';
import './Header.css';
import {
    UilClipboardAlt,
    UilFilePlus,
    UilPackage,
    UilEstate,
    UilEnvelope,
    UilUsersAlt,
    UilSignout,
    UilUserCircle
} from "@iconscout/react-unicons";
import apiFinder, {baseURL} from "../../apis/apiFinder";

const withDefaultParams = (IconComponent) => ({ size = 24, color = '#000000', ...props }) => {
    return <IconComponent size={size} color={color} {...props} />;
};

const OrderIcon = withDefaultParams(UilClipboardAlt);
const AddOrderIcon = withDefaultParams(UilFilePlus);
const ProductIcon = withDefaultParams(UilPackage);
const EstateIcon = withDefaultParams(UilEstate);
const EnvelopeIcon = withDefaultParams(UilEnvelope);
const UsersIcon = withDefaultParams(UilUsersAlt);
const SignoutIcon = withDefaultParams(UilSignout);
const UserIcon = withDefaultParams(UilUserCircle);

const Header = () => {
    const { user, logout } = useAuth();
    const [profilePicture, setProfilePicture] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    },
                };
                const response = await axios.get(baseURL+'/user',config);

                // const config = {
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                //     },
                // };
                // const response = await apiFinder.get("/user", config);


                console.log("User data response:", response.data);
                setError("User data response:", response.data);
                const userData = response.data.user;
                const profilePictureUrl = userData.Images ? `http://localhost:3301/uploads/${userData.Images}` : 'http://localhost:3301/uploads/default.png';

                setProfilePicture(profilePictureUrl);
            } catch (err) {
                setError(err.response ? err.response.data.message : 'Failed to fetch user data');
            }
        };

        fetchUserData();
    }, []);

    const handleLogout = async (e) => {
        e.preventDefault();
        await logout();
    }

    return (
        <Navbar expand="lg" className="bg-light" fixed="top" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Container fluid>
                <Navbar.Brand href="/home">
                    <img
                        alt=""
                        src={Logo}
                        width="150"
                        height="50"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/home" className="nav-link"> <EstateIcon /> Home</Nav.Link>
                        <div className="vr" />
                        <Nav.Link href="/editProducts" className="nav-link"> <ProductIcon /> Edit Products</Nav.Link>
                        <div className="vr" />
                        <Nav.Link href="/addProducts" className="nav-link"> <AddOrderIcon /> Add Products</Nav.Link>
                        <div className="vr" />
                        <Nav.Link href="/orders" className="nav-link"> <OrderIcon /> Orders</Nav.Link>
                        <div className="vr" />
                        {/* Admin Links */}
                        {user && user.role === 'admin' && (
                            <>
                                <Nav.Link href="/manageUsers" className="nav-link"><UsersIcon />Manage Users</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link href="/Messages" className='text-center nav-link'><EnvelopeIcon /></Nav.Link>
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="link" id="profile-dropdown" className="text-center nav-link profile-icon">
                                <img
                                    src={profilePicture ? profilePicture : 'http://localhost:3301/uploads/default.png'}
                                    alt="Profile Picture"
                                    className="img-fluid rounded-circle"
                                    style={{ width: '40px', height: '40px' }}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/UserProfile"> <UserIcon /> View Profile</Dropdown.Item>
                                <Dropdown.Item href="/Settings">Settings</Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}> <SignoutIcon /> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
