import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import Order from '../components/Orders/Order';
const OrdersPage = () => {
  useEffect(() => {
    document.title = "Orders";
  }, []);
  return (
    <Layout>
      <Order/>
    </Layout>
  )
}

export default OrdersPage
