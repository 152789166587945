import React, { useEffect } from 'react';
import LoginForm from '../components/Login/LoginForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import backgroundVideo from '../videos/video2.mp4';
const LoginPage = () => {
  useEffect(() => {
    document.title = "Login";
  }, []);
  return (
    
    <>
      {/* <Container className='border border-danger'  style={{
    display: 'flex',
    height: '100vh',
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)),url(${backgroundImage})`, // 使用模板字符串插入图片路径
    backgroundSize: 'cover', // 覆盖整个容器
    backgroundPosition: 'center', // 图片居中
    backgroundRepeat: 'no-repeat' // 不重复图片
  }}  fluid>
       */}
       <Container>
       <video autoPlay muted loop style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',objectFit:'cover',zIndex:'-1',opacity:'0.9' }}>
        <source src={backgroundVideo} type='video/mp4' />
      </video>
       </Container>
       <Container className='d-flex  align-items-center justify-content-center' style={{ display: 'flex', height: '100vh',width:'50%'}} >
          {/* <LoginVideo/> */}
          <LoginForm/>
      </Container>
        
    </>
  )
}

export default LoginPage;
