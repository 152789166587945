import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const UploadNewProduct = ({apiProducts,getApiProducts}) => {
    const [checkedProducts, setCheckedProducts] = useState({});
    if (!apiProducts || !getApiProducts) {
        return <p>Loading products...</p>;  // 或者其他适当的加载指示或错误消息
      }
      const uniqueProducts = apiProducts.filter(apiProduct =>
        !getApiProducts.some(getApiProduct => getApiProduct.code === apiProduct.code)
      );
        // 处理复选框更改
    const handleCheckboxChange = (code, index, isChecked) => {
        setCheckedProducts(prevState => ({
            ...prevState,
            [`${code}-${index}`]: isChecked
        }));
    };
    function formatTimeDifference(updatedAtString) {
        const updatedAt = new Date(updatedAtString);
        const now = new Date();
        const diff = now - updatedAt; // 毫秒差
    
        const diffInMinutes = Math.floor(diff / (1000 * 60)); // 转换为分钟
        const minutes = diffInMinutes % 60;
        const diffInHours = Math.floor(diffInMinutes / 60);
        const hours = diffInHours % 24;
        const days = Math.floor(diffInHours / 24);
    
        return `${days}Days${hours}Hours${minutes}Minutes Before`;
    }
    const handleUploadNewProduct =async() => {
        try{
           const select= getSelectedProducts();
            // console.log(selectedProducts);
            const config={
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                },
              }
              const response = await axios.post('http://localhost:3301/api/uploadNewProducts', select,config);
              if (response.status === 200&&response.data.success) {
                // 处理成功保存数据的情况
                console.log(response.data.results);
                console.log('Product upload product successfully');
      
              } else {
                // 处理无法保存数据的情况
                console.log(response.data.message);
                console.error('Failed to update');
              }
        }catch(error){
            console.error('Error upload product :', error); 
        }
    }
       // 获取所有选中的项
       const getSelectedProducts = () => {
        return apiProducts.filter((product, index) =>
            checkedProducts[`${product.code}-${index}`]
        ).map(product=>({
            code:product.code,
            mfCode:product.mfCode,
            model:product.model,
            title:product.title,
            shortTitle:product.shortTitle,
            industry:product.industry,
            brand:product.brand,
            category:product.category,
            tkofCategoryId:product.tkofCategoryId,
            clientProductURL:product.clientProductURL,
            color:product.color,
            description:product.description,
            shortDescription:product.shortDescription,
            material:product.material,
            origin:product.origin,
            productImages: product.productImages ? product.productImages.split(';') : [],
            packageImages: product.packageImages ? product.packageImages.split(';') : [],
            productType:product.productType,
            sizeChartType:product.sizeChartType,
            sizeChartImage:product.sizeChartImage,
            sizeType:product.sizeType,
            usage:product.productUsage,
            saleType:product.saleType,
            publishSeason:product.publishSeason,
            usage:product.productUsage,
            attributes: [],
            customAttributes: [
                {
                    [product.AttributeKey1]: product.AttributeValue1
                }
            ],
            optAttributes:[],
            filingAttributes:[],
            items:product.items.map(item=>({
                sku:item.sku,
                outerSku:"",
                barcode:item.barcode,
                mfBarcode:item.mfBarcode,
                additionalBarcode:[],
                size:item.size,
                variationValue:item.variationValue,
                weight:item.weight,
                weightUnit:item.weightUnit,
                dimension:item.dimension,
                currency:item.currency,
                priceMsrp:item.priceMsrp,
                priceCost:item.priceCost,
                priceRetail:item.priceMsrp,
                saleCurrency:item.currency,
                saleMsrp: item.priceMsrp,
                saleCost: item.priceCost,
                saleRetail: item.priceMsrp,
                qty:item.qty,
                taxRate:item.taxRate,
                alive:item.alive
        
            }))
        }));
        // setSelectedProducts(selected);
    };

  return (
    <Container fluid>
    {uniqueProducts.length>0?(
        <Table>
<thead>
      <tr>
        <th></th>
        <th>#</th>
        <th>Code</th>
        <th>MfCode</th>
        <th>Title</th>
        <th>ShortTitle</th>
        <th>Industry</th>
        <th>brand</th>
        <th>Category</th>
        <th>TkOfCategory</th>
        <th>Color</th>
        <th>Description</th>
        <th>ShortDescription</th>
        <th>Material</th>
        <th>Origin</th>
        <th>ProductImages</th>
        <th>PackageImages</th>
        <th>ProductType</th>
        <th>SizeChartType</th>
        <th>SizeChartImage</th>
        <th>SizeType</th>
        <th>Usage</th>
        <th>PublishSeason</th>
        <th>AttributeKey1</th>
        <th>AttributeValue1</th>
      </tr>
    </thead>
    <tbody > 
    {
uniqueProducts.map((product, productIndex) => {
        
    return (
<React.Fragment key={`newproduct-fragment-${product.code}-${productIndex}`}>
  <tr key={`product-${productIndex}`}>
  <td ><Form.Check
  type='checkbox'
  id={`productCheck-${product.code}-${productIndex}`}
  checked={!!checkedProducts[`${product.code}-${productIndex}`]} // 确保使用双重否定来转换为布尔值
  onChange={e => handleCheckboxChange(product.code, productIndex, e.target.checked)}
   /></td>
    <td>{productIndex + 1}</td>
   <td>{product.code}</td>
   <td>{product.mfCode}</td>
   <td>{product.title}</td>
   <td>{product.shortTitle}</td>
   <td>{product.industry}</td>
   <td>{product.brand}</td>
   <td>{product.category}</td>
   <td>{product.tkofCategoryId}</td>
   <td>{product.color}</td>
   <td>{product.description}</td>
   <td>{product.shortDescription}</td>
   <td>{product.material}</td>
   <td>{product.origin}</td>
   <td>{product.productImages}</td>
   <td>{product.packageImages}</td>
   <td>{product.productType}</td>
   <td>{product.sizeChartType}</td>
   <td>{product.sizeChartImage}</td>
   <td>{product.sizeType}</td>
   <td>{product.productUsage}</td>
   <td>{product.publishSeason}</td>
   <td>{product.AttributeKey1}</td>
   <td>{product.AttributeValue1}</td>
   <td>{formatTimeDifference(product.updatedAt)}</td>
  </tr>
  <tr>
        <th>Items:</th>
        <th>Sku</th>
        <th>Barcode</th>
        <th>mfBarcode</th>
        <th>Size</th>
        <th>VariationValue</th>
        <th>weight</th>
        <th>weightUnit</th>
        <th>dimension</th>
        <th>currency</th>
        <th>priceMsrp</th>
        <th>priceCost</th>
        <th>Quantity</th>
        <th>taxRate</th>
        <th>alive</th>
  </tr>
  {product.items.length > 0 ? (
    product.items.map((item, itemIndex) => (
      <tr key={`item-${productIndex}-${item.sku}`}>
        <td></td>
        <td>{item.sku}</td>
        <td>{item.barcode}</td>
        <td>{item.mfBarcode}</td>
        <td>{item.size}</td>
        <td>{item.variationValue}</td>
        <td>{item.weight}</td>
        <td>{item.weightUnit}</td>
        <td>{item.dimension}</td>
        <td>{item.currency}</td>
        <td>{item.priceMsrp}</td>
        <td>{item.priceCost}</td>
        <td>{item.qty}</td>
        <td>{item.taxRate}</td>
        <td>{item.alive ? <span>True</span> : <span>False</span>}</td>
      </tr>
      
    ))
  ) : (
    <tr>
      <td colSpan="3">Price loading...</td>
    </tr>
  )}
     
</React.Fragment>
    )
})
}
    </tbody>


</Table>
    ):(
        <p>No New products found.</p> 
    )}
    
   
   <Button variant='success' className='text-center' style={{width: '100%',position:'sticky',bottom:'60px'}} onClick={handleUploadNewProduct } >Selected Upload </Button> 
   </Container>
  )
}

export default UploadNewProduct
