import React from 'react';
import Layout from '../components/Basic/Layout';
import Messages from '../components/Messages/Messages';
const MessagesPage = () => {
  return (
    <Layout>
      <Messages/>
    </Layout>
  )
}

export default MessagesPage