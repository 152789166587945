import React, {useEffect} from "react";
import ResetPassword from "../components/Login/ResetPassword";
import backgroundVideo from '../videos/video2.mp4'

const ResetPasswordPage = () => {
    useEffect(() => {
        document.title = "Reset Password";
      }, []);
  return (
    <div className="d-flex justify-content-center align-items-center">
      <video
        autoPlay
        muted
        loop
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1",
          opacity: "0.9",
        }}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div>
        <ResetPassword />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
