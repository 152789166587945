import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Container from 'react-bootstrap/Container';
import apiFinder from '../../apis/apiFinder';
import axios from 'axios';

const Dashboard = () => {
  const [ordersData, setOrdersData] = useState({
    openOrders: [],
    ackOrders: [],
    cancelRequestOrders: [],
    terminatedOrders: [],
    shippedOrders: [],
    canceledOrders: [],
    completeOrders: [],
  });
  const [isAdmin, setIsAdmin] = useState(false); // State to track if the user is an admin

  useEffect(() => {
    const fetchOrdersData = async () => {
      let response; // Declare response here
      try {
        const token = sessionStorage.getItem('token');
        response = await axios.get(`${window.baseURL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("User data response:", response.data); // Log the response

        // Check if the user is an admin and update the state
        if (response.data.user.role === 'admin') {
          setIsAdmin(true);
        }
      } catch (error) {
        console.log('Failed to fetch user data');
        return; // Exit the function if there's an error
      }

      // Fetch orders based on user role
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          },
        };

        if (isAdmin) {
          // Fetch data from the API for admin users
          const ordersResponse = await apiFinder.post('/api/getOrdersDashboard', {}, config);
          if (ordersResponse.data.success) {
            setOrdersData(ordersResponse.data.dashboardOrders);
            console.log('Fetching data for admin');
          } else {
            console.error('Failed to fetch orders for admin');
          }
        } else {
          // Fetch data from the database for users
          const token = sessionStorage.getItem('token');

          const dashboardResponse = await axios.get(`${window.baseURL}/userDashboard`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (dashboardResponse.data.success) {
            // Ensure the data is structured correctly
            const groupedOrders = {
              openOrders: dashboardResponse.data.dashboardOrders['Open'] || [],
              ackOrders: dashboardResponse.data.dashboardOrders['Acknowledged'] || [],
              cancelRequestOrders: dashboardResponse.data.dashboardOrders['CancelRequest'] || [],
              terminatedOrders: dashboardResponse.data.dashboardOrders['Terminated'] || [],
              shippedOrders: dashboardResponse.data.dashboardOrders['Shipped'] || [],
              canceledOrders: dashboardResponse.data.dashboardOrders['Canceled'] || [],
              completeOrders: dashboardResponse.data.dashboardOrders['Complete'] || [],
            };
            setOrdersData(groupedOrders);
            console.log('Fetching data for regular user');
          } else {
            console.error('Failed to fetch orders for regular user');
          }
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrdersData();
  }, [isAdmin]);

  const orderStatusCount = {
    Open: ordersData.openOrders.length,
    Acknowledged: ordersData.ackOrders.length,
    CancelRequest: ordersData.cancelRequestOrders.length,
    Terminated: ordersData.terminatedOrders.length,
    Shipped: ordersData.shippedOrders.length,
    Canceled: ordersData.canceledOrders.length,
    Complete: ordersData.completeOrders.length,
  };

  const platformAmountByStatus = {
    Open: ordersData.openOrders.reduce((sum, order) => sum + order.platformAmount, 0),
    Acknowledged: ordersData.ackOrders.reduce((sum, order) => sum + order.platformAmount, 0),
    CancelRequest: ordersData.cancelRequestOrders.reduce((sum, order) => sum + order.platformAmount, 0),
    Terminated: ordersData.terminatedOrders.reduce((sum, order) => sum + order.platformAmount, 0),
    Shipped: ordersData.shippedOrders.reduce((sum, order) => sum + order.platformAmount, 0),
    Canceled: ordersData.canceledOrders.reduce((sum, order) => sum + order.platformAmount, 0),
    Complete: ordersData.completeOrders.reduce((sum, order) => sum + order.platformAmount, 0),
  };

  const completeOrderItems = ordersData.completeOrders.reduce((acc, order) => {
    // Check if order.items is defined and is an array
    if (order.items && Array.isArray(order.items)) {
      order.items.forEach(item => {
        acc[item.sku] = (acc[item.sku] || 0) + item.qty;
      });
    }
    return acc;
  }, {});

  const orderStatusLabels = Object.keys(orderStatusCount);
  const orderStatusData = Object.values(orderStatusCount);

  const platformAmountLabels = Object.keys(platformAmountByStatus);
  const platformAmountData = Object.values(platformAmountByStatus);

  const skuLabels = Object.keys(completeOrderItems);
  const skuData = Object.values(completeOrderItems);

  const orderStatusChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: orderStatusLabels,
    },
  };

  const platformAmountChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: platformAmountLabels,
    },
  };

  const skuChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: skuLabels,
    },
  };

  return (
    <Container style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.4)' }} fluid>
      <div className='d-flex justify-content-center p-2'>
        <h1>Dashboard</h1>
      </div>
      {isAdmin ? (
        <>
          <h2>Order Status Count</h2>
          <Chart options={orderStatusChartOptions} series={[{ name: 'Order Status Count', data: orderStatusData }]} type="bar" height={350} />

          <h2>Platform Amount by Status</h2>
          <Chart options={platformAmountChartOptions} series={[{ name: 'Platform Amount', data: platformAmountData }]} type="bar" height={350} />

          <h2>Complete Order Items</h2>
          <Chart options={skuChartOptions} series={[{ name: 'Quantity', data: skuData }]} type="bar" height={350} />
        </>
      ) : (
        <>
          {/* User dashboard */}
          <h2>Order Status Count</h2>
          <Chart options={orderStatusChartOptions} series={[{ name: 'Order Status Count', data: orderStatusData }]} type="bar" height={350} />

          <h2>Complete Order Items</h2>
          <Chart options={skuChartOptions} series={[{ name: 'Quantity', data: skuData }]} type="bar" height={350} />
        </>
      )}
    </Container>
  );
}

export default Dashboard;
