import React from 'react'
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useState,useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UploadPriceApi from './UploadPriceApi';
import UploadInventoryApi from './UploadInventoryApi';
import UploadItemsApi from './UploadItemsApi';
import UploadProductsApi from './UploadProductsApi';
import UploadNewProduct from './UploadNewProduct';
import apiFinder from '../../apis/apiFinder';
const UploadAPI = () => {
  const [key, setKey] = useState('price');
  const [apiProducts,setApiProducts]=useState(null);
  const [getApiProducts,setGetApiProducts]=useState(null);
  useEffect(()=>{
    const fetchProducts = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
        };
        const response = await apiFinder.get("/productsItems", config);
        if (response.data.success) {
          setApiProducts(response.data.productsWithItems);
          
         
        }
      } catch (error) {
        console.log("Get products failed, try again");
      }
    };
  
    fetchProducts();
  
  },[]);

  useEffect(()=>{
  const fetchApiProducts = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
      };
      const response = await apiFinder.post("/api/getProducts",[], config);
      if (response.data.success) {
        setGetApiProducts(response.data.data);
      }
    } catch (error) {
      console.log("Get products failed, try again");
    }
  };
  fetchApiProducts();
 
},[]);

  return (
    <Container fluid>
      <Tabs activeKey={key} id='uploadApiTabs' onSelect={(k) => setKey(k)} className='mb-3' justify  style={{width: '100%',position:'sticky', top:'80px',borderBlockColor:'green' }}>
        <Tab eventKey="price" title='Price'>
         <UploadPriceApi apiProducts={apiProducts} getApiProducts={getApiProducts}/>
        </Tab>
        <Tab eventKey="inventory" title='Inventory'>
      <UploadInventoryApi apiProducts={apiProducts} getApiProducts={getApiProducts}/>
        </Tab>
        <Tab eventKey="items" title='Items'>
       <UploadItemsApi apiProducts={apiProducts} getApiProducts={getApiProducts}/>
        </Tab>
        <Tab eventKey="products" title='Products'>
     <UploadProductsApi apiProducts={apiProducts } getApiProducts={getApiProducts}/>
        </Tab>
        <Tab eventKey="newproducts" title='NewProducts'>
     <UploadNewProduct apiProducts={apiProducts } getApiProducts={getApiProducts}/>
        </Tab>
      </Tabs>

        
    </Container>
  )
}

export default UploadAPI
