import React from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Logo from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import apiFinder from '../../apis/apiFinder';

const SearchProduct = ({ products }) => {
  const navigate = useNavigate();
  const [searchFields, setSearchFields] = useState({
    searchTitle: '',
    searchCode: '',
    searchSku: '',
    searchBarcode: '',
    searchBrand: '',
  });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [replaceTime, setReplaceTime] = useState(null); // 用于存储替换数据的时间
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };
  const handleSearch = () => {
    const results = products.filter(product => {
      // 检查标题、代码、品牌等非嵌套属性，确保它们存在且不为 null
      const matchesNonNestedFields = (
        (searchFields.searchTitle ? product.title?.toLowerCase().includes(searchFields.searchTitle.toLowerCase()) : true) &&
        (searchFields.searchCode ? product.code?.toLowerCase().includes(searchFields.searchCode.toLowerCase()) : true) &&
        (searchFields.searchBrand ? product.brand?.toLowerCase().includes(searchFields.searchBrand.toLowerCase()) : true)
      );

      // 检查嵌套在 items 数组中的 sku 和 barcode，确保它们存在且不为 null
      const matchesNestedFields = product.items.some(item => (
        (searchFields.searchSku ? item.sku?.toLowerCase().includes(searchFields.searchSku.toLowerCase()) : true) &&
        (searchFields.searchBarcode ? item.barcode?.toLowerCase().includes(searchFields.searchBarcode.toLowerCase()) : true)
      ));

      // 产品需要满足非嵌套和嵌套条件之一
      return matchesNonNestedFields && matchesNestedFields;
    });
    setFilteredProducts(results);
    setShowResults(true);
    console.log("Filtered Products: ", filteredProducts);
  };
  const handleViewMore = (product) => () => {
    navigate('/viewmoreproduct', { state: { product } });
  }
  const selectImage = (product) => {
    // 尝试使用packageImages的第一张图片
    const productImages = product.productImages ? product.productImages.split(';') : [];
    const packageImages = product.packageImages ? product.packageImages.split(';') : [];
    if (packageImages.length > 0) {
      return packageImages[0];
    } else if (productImages.length > 0) {
      return productImages[0];
    } else {
      return { Logo }; // 替换为你的默认图片路径或null
    }
  };
  const hanldeReplaceData = async () => {

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
      };
      const response = await apiFinder.post('/replaceData', {}, config);
      if (response.status === 200 && response.data.success) {
        const currentTime = new Date().toLocaleString(); // 获取当前时间
        setReplaceTime(currentTime); // 设置替换数据的时间
        alert('replaced data ');
      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <Container>
        <Row className='mb-2'>
          <Button variant='danger' onClick={hanldeReplaceData}>Replace local data with api data</Button>
        </Row>
        <Form className='mb-2'>
          <Row className='mb-3 '>
            <InputGroup as={Col}  >
              <InputGroup.Text id='searchTitle'>Title</InputGroup.Text>
              <Form.Control type='text' name='searchTitle' placeholder='Input product title' aria-describedby="searchTitle" onChange={handleChange} />
            </InputGroup>
            <InputGroup as={Col}   >
              <InputGroup.Text id='searchCode'>Code</InputGroup.Text>
              <Form.Control type='text' name='searchCode' placeholder='Input product code' aria-describedby='searchCode' onChange={handleChange} />
            </InputGroup>
            <InputGroup as={Col}   >
              <InputGroup.Text id='searchBrand'>Brand</InputGroup.Text>
              <Form.Control type='text' name='searchBrand' placeholder='Input product brand' aria-describedby='searchBrand' onChange={handleChange} />
            </InputGroup>
          </Row>
          <Row className='mb-3 '>
            <InputGroup as={Col}  >
              <InputGroup.Text id='searchBarcode'>Barcode</InputGroup.Text>
              <Form.Control type='text' name='searchBarcode' placeholder='Input product barcode' aria-describedby="searchBarcode" onChange={handleChange} />
            </InputGroup>
            <InputGroup as={Col}  >
              <InputGroup.Text id='searchSku'>SKU</InputGroup.Text>
              <Form.Control type='text' name='searchSku' placeholder='Input product sku' aria-describedby='searchSku' onChange={handleChange} />
            </InputGroup>
            <InputGroup as={Col} >
              <Button style={{ width: '100%' }} onClick={handleSearch}> Search</Button>
            </InputGroup>
          </Row>
        </Form>
      </Container>

      {showResults && (
        filteredProducts.length > 0 ? (
          filteredProducts.map(product => (
            <Container className='border border-danger d-flex flex-wrap justify-content-start' style={{ marginTop: '10px' }} fluid>
              <Card key={product.productId} style={{ width: '18rem', height: '25rem', overflowY: 'auto' }} className='text-center border border-primary'>
                <Card.Img variant="top" src={selectImage(product)} style={{ width: '13rem', height: '13rem' }} />
                <Card.Body>
                  <Card.Title>Code: {product.code}</Card.Title>
                  <Card.Text>Title: {product.title}<br />Brand: {product.brand}</Card.Text>
                  <Button variant='outline-success' onClick={handleViewMore(product)}>View</Button>
                </Card.Body>
              </Card>
            </Container>
          ))

        ) : (

          <div className='alert alert-danger text-center'>
            <p>No products found.</p>
          </div>
        )
        
      )}
      {replaceTime && <p>Data was last replaced at: {replaceTime}</p>}
    </>
  )
}

export default SearchProduct;
