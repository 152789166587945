import React from 'react'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useAuth } from '../../Hooks/AuthContext';
import Button from 'react-bootstrap/esm/Button';
import { useState } from 'react';
import axios from 'axios';
import apiFinder from '../../apis/apiFinder';

const UpdateQuantity = () => {
  const [updateQuantityItem,setUpdateQuantityItem]=useState(null);
  const [searchItem,setSearchItem]=useState(null);
  const [isEditable,setIsEditable]=useState(false);
  const[file,setFile]=useState(null);
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const {user}=useAuth();
  const handleSearchItem=(e)=>{
   e.preventDefault();
   const findItem=async()=>{
   try{
     const config={
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
       },
     }
      const response=await apiFinder.post('/getAItem',searchItem,config);
      if(response.status===200&&response.data.success){
       setUpdateQuantityItem(response.data.itemSearched[0]);
      }
   }catch(error){
     console.log(error);
   }
   }
   findItem();
 };
 
  const handleSearchChange=(e)=>{
   const { name, value } = e.target;
   setSearchItem({
     ...searchItem,
     [name]:value,
   })
  }
  const handleUpdateChange=(e)=>{
 const { name, value } = e.target;
     setUpdateQuantityItem(prev => ({
       ...prev,
       [name]: value,
     }));
  }
  const handleSaveUpdateItem=(e)=>{
    e.preventDefault();
    const updateItem=async()=>{
    try{
      const config={
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
      }
       const response=await axios.post('http://localhost:3301/updateItemsQuantity',[updateQuantityItem],config);
       if(response.status===200&&response.data.success){
        console.log(response.data.message);
        setIsEditable(false);
       // window.location.reload();
       }
    }catch(error){
      console.log(error);
    }
    }
    updateItem();
    
  }
 const handleEditItem=()=>{
   setIsEditable(true);
 }
 const handleCancelEditItem=()=>{
   setIsEditable(false);
    
 }
 const hanldeUploadFileChange=(event)=>{
  setFile(event.target.files[0]);
};
const handleUploadFile=async(event)=>{
  event.preventDefault();
  if (!file) {
    alert('Please select a file.');
    return;
  }
  if (!user) {
      console.error("user valid");
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post('http://localhost:3301/uploadProductItemQuantity', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
     if(response.status===200&&response.data.success){
      console.log(response.data.message)
     }
      
    } catch (error) {
      console.error('Upload failed', error);
    }
};
   return (
    <>
      <Container fluid  >
      <div className='d-flex justify-content-center p-2'>
          <h1>Update an item's quantity</h1>
        </div>
    <Form className=' border border-danger'  >
      <Row className='' style={{marginTop:'5px'}}>
        <Col>
        <Form.Control type="file" onChange={hanldeUploadFileChange}/>
        </Col>
        <Col>
        <Button type='submit' onClick={handleUploadFile}>Upload</Button>
        </Col>
      </Row>
    </Form>
     <Form  className='border border-danger' style={{ marginTop: '10px',maxWidth:'100%' }}>
     <Row className='mb-3'>
     <Form.Group as={Col} controlId='searchSku' >
        <Form.Control type='text' placeholder="input item's sku "  name='searchSku' onChange={handleSearchChange} />
       </Form.Group>
       <Form.Group as={Col} controlId='searchBarcode' >
        <Form.Control type='text' placeholder="input item's barcode "  name='searchBarcode' onChange={handleSearchChange}/>
       </Form.Group>
       <Col>
       <Button type='submit' onClick={handleSearchItem}>Search</Button>
       </Col>
     </Row>
    </Form >
    {updateQuantityItem&&(<Form className='border border-warning' style={{ marginTop: '10px',maxWidth:'100%' }}>
   <Row className='mb-3'>
       <Form.Group as={Col} controlId='productCode' >
         <Form.Label>Code</Form.Label>
        <Form.Control type='text' defaultValue={updateQuantityItem.productCode} readOnly name='productCode'/>
       </Form.Group>
       <Form.Group as={Col} controlId='sku' >
         <Form.Label>Sku</Form.Label>
        <Form.Control  type='text' defaultValue={updateQuantityItem.sku}  readOnly name='sku'/>
       </Form.Group>
       <Form.Group as={Col} controlId='barcode' >
         <Form.Label>barcode</Form.Label>
        <Form.Control  type='text' defaultValue={updateQuantityItem.barcode}  readOnly name='barcode'/>
       </Form.Group>
       </Row>
       <Row className='mb-3'>
       <Form.Group as={Col} controlId='qty' >
         <Form.Label>Quantity/Inventory</Form.Label>
        <Form.Control  type='text' defaultValue={updateQuantityItem.qty} readOnly={!isEditable} name='qty' onChange={handleUpdateChange}/>
       </Form.Group>
     </Row>
      {!isEditable&&<Button onClick={handleEditItem}>Edit Item</Button>}
      {isEditable&&<Button onClick={handleSaveUpdateItem}>Save</Button>}
      {isEditable&&<Button onClick={handleCancelEditItem}>Cancel</Button>}
     </Form>
 )}
 
     </Container>
     </>
   )
}

export default UpdateQuantity
