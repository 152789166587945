import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import apiFinder from '../../apis/apiFinder';

const TerminatedOrders = () => {
  const [terminatedUserOrders, setTerminatedUserOrders] = useState([]);
  const [terminatedOrders, setTerminatedOrders] = useState([]);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${window.baseURL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(response.data.user);
      } catch (err) {
        setError(err.response ? err.response.data.message : 'Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      if (userData && userData.role === 'admin') {
        try {
          const token = sessionStorage.getItem('token');
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          };
          const status = "Terminated";
          const response = await apiFinder.post("/api/getOrdersByStatus", { status }, config);
          if (response.data.success) {
            setTerminatedOrders(response.data.orders);
          }
        } catch (error) {
          setError("Failed to fetch terminated orders.");
        }
      }
    };
    fetchOrders();
  }, [userData]);

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const token = sessionStorage.getItem('token');

        const response = await axios.get(`${window.baseURL}/terminatedorders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTerminatedUserOrders(response.data.orders);
      } catch (err) {
        setError(err.response ? err.response.data.message : 'Failed to fetch terminated user orders');
      }
    };
    fetchUserOrders();
  }, []);

  if (loading) {
    return <Container fluid><p>Loading...</p></Container>;
  }

  if (error) {
    return <Container fluid><p>{error}</p></Container>;
  }

  return (
    <Container fluid>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Order:</th>
            <th>OrderId</th>
            <th>OrderTime</th>
            <th>platformId</th>
            <th>cartId</th>
            <th>cartOrderId</th>
            <th>orderStatus</th>
            <th>fromWarehouseKey</th>
            <th>platformCurrency</th>
            <th>platformAmount</th>
            <th>platformDiscount</th>
            <th>platformTax</th>
            <th>platformShippingFee</th>
            <th>exchangeRate</th>
            <th>currency</th>
            <th>amount</th>
            <th>discount</th>
            <th>tax</th>
            <th>shippingFee</th>
          </tr>
        </thead>
        <tbody>
          {(userData && userData.role === 'admin' ? terminatedOrders : terminatedUserOrders).length > 0 ? (
            (userData && userData.role === 'admin' ? terminatedOrders : terminatedUserOrders).map((order, orderIndex) => (
              <React.Fragment key={`terminatedOrder-${orderIndex}`}>
                <tr>
                  <td>{orderIndex + 1}</td>
                  <td>{order.orderId}</td>
                  <td>{order.orderTime}</td>
                  <td>{order.platformId}</td>
                  <td>{order.cartId}</td>
                  <td>{order.cartOrderId}</td>
                  <td>{order.orderStatus}</td>
                  <td>{order.fromWarehouseKey}</td>
                  <td>{order.platformCurrency}</td>
                  <td>{order.platformAmount}</td>
                  <td>{order.platformDiscount}</td>
                  <td>{order.platformTax}</td>
                  <td>{order.platformShippingFee}</td>
                  <td>{order.exchangeRate}</td>
                  <td>{order.currency}</td>
                  <td>{order.amount}</td>
                  <td>{order.discount}</td>
                  <td>{order.tax}</td>
                  <td>{order.shippingFee}</td>
                </tr>
                {order.items && order.items.length > 0 && (
                  <>
                    <tr>
                      <th colSpan="18">Items:</th>
                    </tr>
                    {order.items.map((item, itemIndex) => (
                      <tr key={`terminatedOrderItem-${itemIndex}`}>
                        <td></td>
                        <td>{item.sku}</td>
                        <td>{item.code}</td>
                        <td>{item.model}</td>
                        <td>{item.title}</td>
                        <td>{item.qty}</td>
                        <td>{item.platformUnitPrice}</td>
                        <td>{item.platformAmount}</td>
                        <td>{item.platformTax}</td>
                        <td>{item.platformDiscount}</td>
                        <td>{item.platformShippingFee}</td>
                        <td>{item.cost}</td>
                        <td>{item.unitPrice}</td>
                        <td>{item.amount}</td>
                        <td>{item.tax}</td>
                        <td>{item.discount}</td>
                        <td>{item.shippingFee}</td>
                      </tr>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="18">No orders found.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TerminatedOrders;
