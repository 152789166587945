import React, {useEffect} from 'react';
import Layout from '../components/Basic/Layout';
import ChangesHistory from '../components/History/ChangesHistory';

const ChangesHistoryPage = () => {
    useEffect(() => {
        document.title = "History";
      }, []);
    return(
        <Layout><ChangesHistory/></Layout>
    )
}

export default ChangesHistoryPage